import React from 'react';
import { FILE_URL } from '../../../redux/env';

const TemplateTile = ({ template, isSelected, onSelect, }) => {
  return (
    <div className='w-full md:max-xl relative justify-center text-center'>
      <div 
        className={`mx-auto mb-2 bg-primary-50 p-4 relative mt-2 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 cursor-pointer ${isSelected ? 'border-2 border-primary-500' : 'border-2 border-primary-50'}`} 
        onClick={() => onSelect(template)}
      >
        <div className="relative">
          <img 
            src={FILE_URL + template.template_bg_file} 
            className='object-cover rounded-lg mb-2'
            alt={template.name} 
          />
          <div className="absolute top-2 right-2 bg-white rounded-full p-1">
            <input
              type="checkbox"
              id={`template-${template.id}`}
              checked={isSelected}
              onChange={() => onSelect(template)}
              className="custom-checkbox"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>
      <a href={FILE_URL + template.template_file} target='_blank' className="text-md text-center text-primary-600 hover:text-primary-700 font-semibold text-center">{template.name}</a>
    </div>
  );
};

export default TemplateTile;
