import React from 'react';
import HomePageHero from './Home/HomePageHero';
import Features from './Home/Features';
import Testimonials from './Home/Testimonials';
import FAQ from './Home/FAQ';
import CallToAction from './Home/Calltoaction';

const Home = () => {
    return (
        <div> 
            <HomePageHero />
            <Features />
            <Testimonials />
            <FAQ />
            <CallToAction />
        </div>
    );
};

export default Home;
