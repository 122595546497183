import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResumeDetails, updateResume } from '../../../redux/actions/resumeActions';
import html2pdf from 'html2pdf.js';
import './../../../style/resumes2.css'; // Import the CSS file for styling

const ResumePreview2 = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state;

    const getResumeDetails = useSelector((state) => state.resumeDetails.resumeDetails);
    const resumeContentRef = useRef(null);

    const [isEditing2, setIsEditing2] = useState(false);
    const [resumeData2, setResumeData2] = useState(null);

    useEffect(() => {
        dispatch(fetchResumeDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (getResumeDetails) {
            const { resume_content } = getResumeDetails;
            setResumeData2(JSON.parse(resume_content));
        }
    }, [getResumeDetails]);

    const handleDownload = () => {
        const element = resumeContentRef.current;
        const options = {
            margin: [0, 0, 0, 0], // Adjust margins as needed
            filename: 'resume.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };

        html2pdf().from(element).set(options).save();
    };

    const handleEditToggle = () => {
        setIsEditing2(!isEditing2);
    };

    const handleInputChange = (section, index, field, value) => {
        setResumeData2((prevData) => {
            const updatedData = { ...prevData };
            if (section === 'contact_information') {
                updatedData.contact_information[field] = value;
            } else if (section === 'skills') {
                updatedData.skills = value.split(', ');
            } else if (section === 'languages') {
                updatedData.languages = value.split(', ');
            } else if (section === 'experience' || section === 'education' || section === 'projects') {
                updatedData[section][index][field] = value;
            }
            return updatedData;
        });
    };

    const handleSave = () => {
        const updatedResumeContent = JSON.stringify(resumeData2);
        dispatch(updateResume(id, { resume_content: updatedResumeContent }));
        setIsEditing2(false);
    };

    if (!resumeData2) {
        return <div>Loading...</div>;
    }

    return (
        <div className="resume-container">
            <main className="resume-content p-8" ref={resumeContentRef}>
                <div className="header text-center mb-2">
                    {resumeData2["contact_information"].name && (isEditing2 ? (
                        <input
                            type="text"
                            value={resumeData2["contact_information"].name}
                            onChange={(e) => handleInputChange("contact_information", null, "name", e.target.value)}
                            className="text-4xl font-bold mb-2 w-full text-center"
                        />
                    ) : (
                        <h1 className="text-4xl font-bold mb-2">{resumeData2["contact_information"].name}</h1>
                    ))}
                    {resumeData2["contact_information"].phone && (isEditing2 ? (
                        <input
                            type="text"
                            value={resumeData2["contact_information"].phone}
                            onChange={(e) => handleInputChange("contact_information", null, "phone", e.target.value)}
                            className="text-lg text-gray-600 mb-1 w-full text-center"
                        />
                    ) : (
                        <p className="text-lg text-gray-600 mb-1">{resumeData2["contact_information"].phone}</p>
                    ))}
                    {resumeData2["contact_information"].email && (isEditing2 ? (
                        <input
                            type="text"
                            value={resumeData2["contact_information"].email}
                            onChange={(e) => handleInputChange("contact_information", null, "email", e.target.value)}
                            className="text-lg text-gray-600 mb-1 w-full text-center"
                        />
                    ) : (
                        <p className="text-lg text-gray-600 mb-1">{resumeData2["contact_information"].email}</p>
                    ))}
                    {resumeData2["contact_information"].location && (isEditing2 ? (
                        <input
                            type="text"
                            value={resumeData2["contact_information"].location}
                            onChange={(e) => handleInputChange("contact_information", null, "location", e.target.value)}
                            className="text-lg text-gray-600 mb-1 w-full text-center"
                        />
                    ) : (
                        <p className="text-lg text-gray-600 mb-1">{resumeData2["contact_information"].location}</p>
                    ))}
                </div>
                <div className="grid grid-cols-2 gap-8">
                    <div className="left-column">
                        {resumeData2.summary && <div className="section mb-8">
                            <h2 className="text-2xl font-bold text-gray-700 mb-2">Summary</h2>
                            {isEditing2 ? (
                                <textarea
                                    value={resumeData2.summary}
                                    onChange={(e) => handleInputChange("summary", null, null, e.target.value)}
                                    className="textarea-field"
                                />
                            ) : (
                                <p>{resumeData2.summary}</p>
                            )}
                        </div>}
                        {resumeData2.skills && resumeData2.skills.length > 0  && <div className="section mb-8">
                            <h2 className="text-2xl font-bold text-gray-700 mb-2">Skills</h2>
                            {isEditing2 ? (
                                <textarea
                                    value={resumeData2.skills.join(', ')}
                                    onChange={(e) => handleInputChange("skills", null, null, e.target.value)}
                                    className="textarea-field"
                                />
                            ) : (
                                <ul className="list-disc pl-5">
                                    {resumeData2.skills.map((skill, index) => (
                                        <li key={index}>{skill}</li>
                                    ))}
                                </ul>
                            )}
                        </div>}
                        {resumeData2.languages && resumeData2.languages.length > 0 && <div className="section mb-8">
                            <h2 className="text-2xl font-bold text-gray-700 mb-2">Languages</h2>
                            {isEditing2 ? (
                                <textarea
                                    value={resumeData2.languages.join(', ')}
                                    onChange={(e) => handleInputChange("languages", null, null, e.target.value)}
                                    className="textarea-field"
                                />
                            ) : (
                                <ul className="list-disc pl-5">
                                    {resumeData2.languages.map((language, index) => (
                                        <li key={index}>{language}</li>
                                    ))}
                                </ul>
                            )}
                        </div>}
                    </div>
                    <div className="right-column">
                        {resumeData2.education && resumeData2.education.length > 0 && <div className="section mb-8">
                            <h2 className="text-2xl font-bold text-gray-700 mb-2">Education</h2>
                            {resumeData2.education.map((edu, index) => (
                                <div key={index} className="mb-4">
                                    {isEditing2 ? (
                                        <>
                                            <input
                                                type="text"
                                                value={edu.level}
                                                onChange={(e) => handleInputChange("education", index, "level", e.target.value)}
                                                className="input-field"
                                                placeholder="Level"
                                            />
                                            <input
                                                type="text"
                                                value={edu.degree}
                                                onChange={(e) => handleInputChange("education", index, "degree", e.target.value)}
                                                className="input-field"
                                                placeholder="Degree"
                                            />
                                            <input
                                                type="text"
                                                value={edu.institution}
                                                onChange={(e) => handleInputChange("education", index, "institution", e.target.value)}
                                                className="input-field"
                                                placeholder="Institution"
                                            />
                                            <input
                                                type="text"
                                                value={edu.start_date}
                                                onChange={(e) => handleInputChange("education", index, "start_date", e.target.value)}
                                                className="input-field"
                                                placeholder="Start Date"
                                            />
                                            <input
                                                type="text"
                                                value={edu.end_date}
                                                onChange={(e) => handleInputChange("education", index, "end_date", e.target.value)}
                                                className="input-field"
                                                placeholder="End Date"
                                            />
                                            {edu.achievements && (
                                                <input
                                                    type="text"
                                                    value={edu.achievements}
                                                    onChange={(e) => handleInputChange("education", index, "achievements", e.target.value)}
                                                    className="input-field"
                                                    placeholder="Achievements"
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="font-semibold">{edu.level}</h3>
                                            <p><strong>{edu.degree}</strong> - {edu.institution}</p>
                                            <p><strong>Start Date:</strong> {edu.start_date}</p>
                                            <p><strong>End Date:</strong> {edu.end_date}</p>
                                            {edu.achievements && <p><strong>Achievements:</strong> {edu.achievements}</p>}
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>}
                        {resumeData2.experience && resumeData2.experience.length > 0 && <div className="section mb-8">
                            <h2 className="text-2xl font-bold text-gray-700 mb-2">Experience</h2>
                            {resumeData2.experience.map((exp, index) => (
                                <div key={index} className="mb-4">
                                    {isEditing2 ? (
                                        <>
                                            <input
                                                type="text"
                                                value={exp.title}
                                                onChange={(e) => handleInputChange("experience", index, "title", e.target.value)}
                                                className="input-field"
                                                placeholder="Title"
                                            />
                                            <input
                                                type="text"
                                                value={exp.company}
                                                onChange={(e) => handleInputChange("experience", index, "company", e.target.value)}
                                                className="input-field"
                                                placeholder="Company"
                                            />
                                            <input
                                                type="text"
                                                value={exp.location}
                                                onChange={(e) => handleInputChange("experience", index, "location", e.target.value)}
                                                className="input-field"
                                                placeholder="Location"
                                            />
                                            <input
                                                type="text"
                                                value={exp.start_date}
                                                onChange={(e) => handleInputChange("experience", index, "start_date", e.target.value)}
                                                className="input-field"
                                                placeholder="Start Date"
                                            />
                                            <input
                                                type="text"
                                                value={exp.end_date}
                                                onChange={(e) => handleInputChange("experience", index, "end_date", e.target.value)}
                                                className="input-field"
                                                placeholder="End Date"
                                            />
                                            <textarea
                                                value={exp.description}
                                                onChange={(e) => handleInputChange("experience", index, "description", e.target.value)}
                                                className="textarea-field"
                                                placeholder="Description"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="font-semibold">{exp.title}</h3>
                                            <p><strong>{exp.company}</strong> - {exp.location}</p>
                                            <p><strong>Start Date:</strong> {exp.start_date}</p>
                                            <p><strong>End Date:</strong> {exp.end_date}</p>
                                            <p>{exp.description}</p>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>}
                        {resumeData2.projects && resumeData2.projects.length > 0 && <div className="section mb-8">
                            <h2 className="text-2xl font-bold text-gray-700 mb-2">Projects</h2>
                            {resumeData2.projects.map((project, index) => (
                                <div key={index} className="mb-4">
                                    {isEditing2 ? (
                                        <>
                                            <input
                                                type="text"
                                                value={project.title}
                                                onChange={(e) => handleInputChange("projects", index, "title", e.target.value)}
                                                className="input-field"
                                                placeholder="Project Title"
                                            />
                                            <input
                                                type="text"
                                                value={project.duration}
                                                onChange={(e) => handleInputChange("projects", index, "duration", e.target.value)}
                                                className="input-field"
                                                placeholder="Duration"
                                            />
                                            <textarea
                                                value={project.description}
                                                onChange={(e) => handleInputChange("projects", index, "description", e.target.value)}
                                                className="textarea-field"
                                                placeholder="Description"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="font-semibold">{project.title}</h3>
                                            <p><strong>Duration:</strong> {project.duration}</p>
                                            <p>{project.description}</p>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>
            </main>
            <div className="flex justify-between mt-4">
                <button
                    onClick={handleDownload}
                    className="px-4 py-2 mr-4 bg-gradient-to-r from-primary-500 to-primary-600 text-white rounded-full shadow-lg hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                >
                    Download Resume
                </button>
                <button
                    onClick={isEditing2 ? handleSave : handleEditToggle}
                    className="text-primary-600 border border-primary-600 px-4 py-2 bg-gradient-to-r hover:text-white rounded-full shadow-lg hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                >
                    {isEditing2 ? 'Save Changes' : 'Edit Resume'}
                </button>
            </div>
        </div>
    );
};

export default ResumePreview2;
