import React from 'react';
import DashboardCards from './Components/DashboardCard';

const DashboardHome = () => {
  const cardData = [
    {
      title: "Create Job",
      description: "Set up a new job position that candidates can apply for.",
      imageurl: "./images/job-1.png",
      link: "/add-job"
    },
    {
      title: "Create Q&A on Basis of Job",
      description: "Generate interview questions and answers based on the job description.",
      imageurl: "./images/qna-1.png",
      link: "/create-qna"
    },
    {
      title: "Create Live Interview Q&A",
      description: "Generate live interview questions and answers dynamically.",
      imageurl: "./images/live-interview-2.png",
      link: "/create-live-qna"
    },
    {
      title: "Create Resume on Basis of Job and Resume",
      description: "Create a tailored resume for a job application based on the job description and user's existing resume.",
      imageurl: "./images/resume-1.png",
      link: "/create-resume"
    }
  ];

  return (
    <div className="flex">
      <div className="flex-1">
        <main className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 mb-6">
            <div>
              <h1 className="text-2xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Interview AI Tools</h1>
              <p className="text-gray-500 dark:text-gray-300">
                Use these tools to create job postings, generate interview questions and answers, conduct live interviews, and create tailored resumes.
              </p>
            </div> 
          </div>

          <div className="grid grid-cols-1 mt-5">
            <DashboardCards data={cardData} />
          </div>

        </main>
      </div>
    </div>
  );
};

export default DashboardHome;
