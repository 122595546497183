import {
    RESUME_DETAILS_LOADING,
    RESUME_DETAILS_SUCCESS,
    RESUME_DETAILS_FAIL,
} from '../constants/resumeConstants';

export const getResumeDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case RESUME_DETAILS_LOADING:
            return { loading: true };

        case RESUME_DETAILS_SUCCESS:
            return { loading: false, resumeDetails: action.payload };

        case RESUME_DETAILS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};