import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaFileInvoice, FaSearch, FaRegTrashAlt } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { getQnAList, deleteQnA } from '../../../redux/actions/qnaActions';
import Container from '../../../Components/Container';
import ContentHeader from '../../../Components/ContentHeader';
import Card from '../../../Components/Card';

const QnaList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [qna, setQna] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const itemsPerPage = 10; 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(getQnAList());
        setQna(data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); 
  }, []); 

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  }; 

  const handleView = (qnaId) => {
    navigate('/que-ans', { state: { id: qnaId }});
  };

  const handleDelete = (qnaId) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this Qna?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await dispatch(deleteQnA(qnaId));
              setQna(qna.filter(qna => qna.id !== qnaId));
              localStorage.removeItem('qnaList');
              toast.success('qna deleted successfully');
            } catch (error) {
              toast.error('Error deleting qna');
              console.error('Delete error:', error);
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const filteredqna = Array.isArray(qna) ? qna.filter((qna) =>
    (qna.job_title && qna.job_title.toLowerCase().includes(filter.toLowerCase())) ||
    (qna.created_at && qna.created_at.includes(filter))
  ) : [];

  const offset = currentPage * itemsPerPage;
  const currentqna = filteredqna.slice(offset, offset + itemsPerPage);
  
  return (
    <Container>
      <ContentHeader heading="Interview QNA" style="text-left" subheading="List of Interview Question Answer" />
      <Card>
      <div className="flex justify-between items-center mb-6">
        <div className="relative w-1/3">
          <FaSearch className="absolute top-2.5 left-3 text-gray-500" />
          <input
            type="text"
            placeholder="Filter qna"
            className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:ring focus:ring-primary-200 focus:border-primary-500"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <Link to="/create-qna" className="flex items-center px-2 md:px-8 py-2 text-white bg-primary-600 rounded-full shadow-md text-white bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 focus:ring-primary-500">
          <FaPlus className="mr-2" />
          Create Qna
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead className="bg-gradient-to-r from-primary-100 to-primary-100">
            <tr>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Job</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Interview Type</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Date</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Action</th>
            </tr>
          </thead>
          <tbody>
          {currentqna.length > 0 ? (
            currentqna.map((qna, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="py-3 px-5 text-sm text-gray-800"><button className="text-primary-700 font-semibold" onClick={() => handleView(qna.id)}>{qna.job_title}</button></td>
                <td className="py-3 px-5 text-sm text-gray-800">{qna.interview_type_name}</td>
                <td className="py-3 px-5 text-sm text-gray-800">{formatDate(qna.created_at)}</td>
                <td className="py-3 px-5 text-sm">
                  <button
                      onClick={() => handleDelete(qna.id)}
                      className="px-4 ml-2 py-2 text-red-500 border border-red-500 bg-white hover:bg-red-500 hover:text-white rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                      <FaRegTrashAlt size={12} />
                  </button>
                </td>
              </tr>
            ))) : (
                <tr>
                  <td colSpan="5" className="py-10 text-center">
                    <div className="flex flex-col items-center">
                      <FaFileInvoice className="mb-3 text-6xl text-gray-400" />
                      <p className="text-lg font-medium text-gray-600">No qna Found</p>
                      <p className="text-sm text-gray-500">Try adding new job!</p>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <div className="mt-6">
      {currentqna.length > 0 && (
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={Math.ceil(filteredqna.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'flex justify-center space-x-2'}
          pageClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
          previousClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
          nextClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
          breakClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
          activeClassName={'bg-primary-600 text-white'}
        />)}
      </div>
      </Card>
      <ToastContainer />
    </Container>
  );
};

export default QnaList;
