import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createQnA, getQnADetails } from '../../../redux/actions/qnaActions';
import 'react-toastify/dist/ReactToastify.css';
import LevelNavigation from './Qna/LevelNavigation';
import QuestionList from './Qna/QuestionList';

const QueAns = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state;
    const [qnaList, setQnaList] = useState([]);
    const [totalLevels, setTotalLevels] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [currentLevel, setCurrentLevel] = useState(1); 

    const qnaDetail = useSelector((state) => state.qnaDetails.qnaDetails);
    
    useEffect(() => {
        dispatch(getQnADetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (qnaDetail && qnaDetail.qna) {
            const parsedQna = JSON.parse(qnaDetail.qna);
            
            if (Array.isArray(parsedQna.interviewQuestions)) {
                setQnaList(parsedQna.interviewQuestions.map((q, index) => ({ ...q, open: index === 0 }))); // Open the first question by default
                setCurrentLevel(1); // Always start from the first level
                setTotalLevels(Math.ceil(parsedQna.interviewQuestions.length / 10));
            }
        } else {
            const storedQnaList = localStorage.getItem('qnaList');
            if (storedQnaList) {
                try {
                    const parsedQnaList = JSON.parse(storedQnaList);
                    if (parsedQnaList && Array.isArray(parsedQnaList.questions)) {
                        setQnaList(parsedQnaList.questions.map((q, index) => ({ ...q, open: index === 0 }))); // Open the first question by default
                        setCurrentLevel(1); // Always start from the first level
                        setTotalLevels(Math.ceil(parsedQnaList.questions.length / 10));
                    } else {
                        console.warn('Parsed qnaList is not an array of questions');
                    }
                } catch (error) {
                    console.error('Error parsing stored qnaList:', error);
                }
            }
        }
    }, [qnaDetail]);

    const nextLevel = async () => {
        if (isLoading || currentLevel >= 2) return;
        setIsLoading(true);
        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);

        let last10Questions = qnaList.slice(-10).map((q, index) => ({
            [`question_${index + 1}`]: q.question,
        }));
 

        const formData = {
            user: userDetail.id,
            id: id,
            job: qnaDetail.job,
            level: currentLevel + 1, // Increment the level for next level questions
            interview_type: qnaDetail.interview_type,
            questions: JSON.stringify({ questions: last10Questions }) // Send the last 10 questions
        };
        try {
            const res = await dispatch(createQnA(formData)); // Assuming createQnA returns the new questions
            if (res && res.qna) {
                const newQuestions = JSON.parse(res.qna).questions;
                setQnaList(prevState => [...prevState, ...newQuestions.map(q => ({ ...q, open: false }))]);
                localStorage.removeItem('qnaList');
                setCurrentLevel(currentLevel + 1);
                setTotalLevels(prevState => prevState + 1);
            }
        } catch (error) {
            console.error('Create error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBackButtonClick = () => {
        window.history.back();
    };

    const prevLevel = () => {
        if (currentLevel > 1) {
            setCurrentLevel(currentLevel - 1);
        }
    };

    const toggleAnswer = (index) => {
        const levelStartIndex = (currentLevel - 1) * 10;
        const questionIndex = levelStartIndex + index;
        setQnaList(prevState => {
            const newState = [...prevState];
            newState[questionIndex] = { ...newState[questionIndex], open: !newState[questionIndex].open };
            return newState;
        });
    };

    const displayedQuestions = qnaList.slice((currentLevel - 1) * 10, currentLevel * 10);
    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 md:py-8">
            <main className="w-full max-w-5xl p-4">
                <div className="grid grid-cols-1 mt-6 md:mb-6">
                    <h1 className="text-center text-2xl md:text-4xl font-bold text-gray-700 md:text-3xl">Interview Questions and Answers</h1>
                    <p className="text-center text-gray-500 mt-2">List of created QNA for Interview!</p>
                </div>
                <div className="md:p-6 flex flex-col items-center">
                    {qnaList.length > 0 && (
                        <div className="w-full bg-white rounded-lg shadow-lg p-2 md:p-8 mt-8">
                            <LevelNavigation
                                currentLevel={currentLevel}
                                totalLevels={totalLevels}
                                isLoading={isLoading}
                                nextLevel={nextLevel}
                                prevLevel={prevLevel}
                                handleBackButtonClick={handleBackButtonClick}
                                setCurrentLevel={setCurrentLevel}
                            />
                            <QuestionList questions={displayedQuestions} toggleAnswer={toggleAnswer} />
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default QueAns;
