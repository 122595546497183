import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResumeDetails, updateResume } from '../../../redux/actions/resumeActions';
import html3pdf from 'html3pdf';
import './../../../style/resumes.css';

const ResumePreview = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state;

    const getResumeDetails = useSelector((state) => state.resumeDetails.resumeDetails);
    const resumeContentRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [resumeData, setResumeData] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(fetchResumeDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (getResumeDetails) {
            const { resume_content } = getResumeDetails;
            setResumeData(JSON.parse(resume_content));
        }
    }, [getResumeDetails]);

    const handleDownload = () => {
        const element = resumeContentRef.current;
        const options = {
            margin: [10, 0, 10, 0],
            filename: 'resume.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        };

        html3pdf().from(element).set(options).save();
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const validateField = (value, field, minLength = 1, maxLength = 200) => {
        if (value.length < minLength) {
            return `${field} must be at least ${minLength} characters.`;
        }
        if (value.length > maxLength) {
            return `${field} must be less than ${maxLength} characters.`;
        }
        return '';
    };

    const handleInputChange = (section, index, field, value, maxLength) => {
        const error = validateField(value, field, 1, maxLength);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [`${section}-${index}-${field}`]: error,
        }));

        if (!error) {
            setResumeData((prevData) => {
                const updatedData = { ...prevData };
                if (section === 'contact_information') {
                    updatedData.contact_information[field] = value;
                } else if (section === 'summary') {
                    updatedData.summary = value;
                } else if (section === 'skills') {
                    updatedData.skills = value;
                } else if (section === 'experience' || section === 'education') {
                    updatedData[section][index][field] = value;
                }
                return updatedData;
            });
        }
    };

    const handleSave = () => {
        const updatedResumeContent = JSON.stringify(resumeData);
        dispatch(updateResume(id, { resume_content: updatedResumeContent }));
        setIsEditing(false);
    };

    if (!resumeData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="resume-container">
            {resumeData && (
                <main className="resume-content" ref={resumeContentRef}>
                    <div className="contact-info text-center mb-6">
                        {isEditing ? (
                            <>
                                <h2 className="section-title text-left">Contact Information</h2>
                                <input
                                    type="text"
                                    value={resumeData.contact_information.name}
                                    onChange={(e) => handleInputChange('contact_information', null, 'name', e.target.value, 200)}
                                    className={`input-field mt-2 ${errors['contact_information-null-name'] ? 'border-red-500' : ''}`}
                                    placeholder="Name"
                                    maxLength={200}
                                />
                                {errors['contact_information-null-name'] && (
                                    <p className="text-red-500 text-xs mt-1">{errors['contact_information-null-name']}</p>
                                )}
                                <input
                                    type="text"
                                    value={resumeData.contact_information.address}
                                    onChange={(e) => handleInputChange('contact_information', null, 'address', e.target.value, 200)}
                                    className={`input-field ${errors['contact_information-null-address'] ? 'border-red-500' : ''}`}
                                    placeholder="Address"
                                    maxLength={200}
                                />
                                {errors['contact_information-null-address'] && (
                                    <p className="text-red-500 text-xs mt-1">{errors['contact_information-null-address']}</p>
                                )}
                                <input
                                    type="text"
                                    value={resumeData.contact_information.phone}
                                    onChange={(e) => handleInputChange('contact_information', null, 'phone', e.target.value, 200)}
                                    className={`input-field ${errors['contact_information-null-phone'] ? 'border-red-500' : ''}`}
                                    placeholder="Phone Number"
                                    maxLength={200}
                                />
                                {errors['contact_information-null-phone'] && (
                                    <p className="text-red-500 text-xs mt-1">{errors['contact_information-null-phone']}</p>
                                )}
                                <input
                                    type="email"
                                    value={resumeData.contact_information.email}
                                    onChange={(e) => handleInputChange('contact_information', null, 'email', e.target.value, 200)}
                                    className={`input-field ${errors['contact_information-null-email'] ? 'border-red-500' : ''}`}
                                    placeholder="Email Address"
                                    maxLength={200}
                                />
                                {errors['contact_information-null-email'] && (
                                    <p className="text-red-500 text-xs mt-1">{errors['contact_information-null-email']}</p>
                                )}
                            </>
                        ) : (
                            <>
                                <h1 className="text-3xl font-bold name">{resumeData.contact_information.name}</h1>
                                <p>{resumeData.contact_information.address}</p>
                                <p>{resumeData.contact_information.phone}</p>
                                <p>{resumeData.contact_information.email}</p>
                            </>
                        )}
                    </div>
                    <div className="section mb-6">
                        {(resumeData.summary || isEditing) && <h2 className="section-title">Profile</h2>}
                        {isEditing ? (
                            <textarea
                                value={resumeData.summary}
                                onChange={(e) => handleInputChange('summary', null, null, e.target.value, 1000)}
                                className={`textarea-field ${errors['summary-null-null'] ? 'border-red-500' : ''}`}
                                placeholder="Profile Summary"
                                maxLength={1000}
                            />
                        ) : (
                            <p>{resumeData.summary}</p>
                        )}
                        {errors['summary-null-null'] && (
                            <p className="text-red-500 text-xs mt-1">{errors['summary-null-null']}</p>
                        )}
                    </div>
                    <div className="page-break"></div>
                    <div className="section mb-6 avoid-page-break">
                        {(resumeData.education && resumeData.education.length > 0 || isEditing) && (
                            <h2 className="section-title">Education</h2>
                        )}
                        {resumeData.education.map((edu, index) => (
                            <div key={index} className="mb-2 avoid-page-break">
                                {isEditing ? (
                                    <>
                                        <input
                                            type="text"
                                            value={edu.institution}
                                            onChange={(e) => handleInputChange('education', index, 'institution', e.target.value, 200)}
                                            className={`input-field ${errors[`education-${index}-institution`] ? 'border-red-500' : ''}`}
                                            placeholder="Institution"
                                            maxLength={200}
                                        />
                                        {errors[`education-${index}-institution`] && (
                                            <p className="text-red-500 text-xs mt-1">{errors[`education-${index}-institution`]}</p>
                                        )}
                                        <input
                                            type="text"
                                            value={edu.degree}
                                            onChange={(e) => handleInputChange('education', index, 'degree', e.target.value, 200)}
                                            className={`input-field ${errors[`education-${index}-degree`] ? 'border-red-500' : ''}`}
                                            placeholder="Degree"
                                            maxLength={200}
                                        />
                                        {errors[`education-${index}-degree`] && (
                                            <p className="text-red-500 text-xs mt-1">{errors[`education-${index}-degree`]}</p>
                                        )}
                                        <input
                                            type="text"
                                            value={edu.start_date}
                                            onChange={(e) => handleInputChange('education', index, 'start_date', e.target.value, 200)}
                                            className={`input-field ${errors[`education-${index}-start_date`] ? 'border-red-500' : ''}`}
                                            placeholder="Start Date"
                                            maxLength={200}
                                        />
                                        {errors[`education-${index}-start_date`] && (
                                            <p className="text-red-500 text-xs mt-1">{errors[`education-${index}-start_date`]}</p>
                                        )}
                                        <input
                                            type="text"
                                            value={edu.end_date}
                                            onChange={(e) => handleInputChange('education', index, 'end_date', e.target.value, 200)}
                                            className={`input-field ${errors[`education-${index}-end_date`] ? 'border-red-500' : ''}`}
                                            placeholder="End Date"
                                            maxLength={200}
                                        />
                                        <textarea
                                            value={edu.description}
                                            onChange={(e) => handleInputChange('education', index, 'description', e.target.value, 1000)}
                                            className={`textarea-field ${errors[`education-${index}-description`] ? 'border-red-500' : ''}`}
                                            placeholder="Description"
                                            maxLength={1000}
                                        />
                                        {errors[`education-${index}-description`] && (
                                            <p className="text-red-500 text-xs mt-1">{errors[`education-${index}-description`]}</p>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <h3 className="font-semibold">{edu.institution}</h3>
                                        <p>{edu.degree} ({edu.start_date} - {edu.end_date})</p>
                                        <p>{edu.description}</p>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="page-break"></div>
                    <div className="section mb-6 avoid-page-break">
                        {(resumeData.skills && resumeData.skills.length > 0 || isEditing) && (
                            <h2 className="section-title">Skills</h2>
                        )}
                        {isEditing ? (
                            <textarea
                                value={resumeData.skills.join(', ')}
                                onChange={(e) => handleInputChange('skills', null, null, e.target.value.split(', '), 1000)}
                                className={`textarea-field ${errors['skills-null-null'] ? 'border-red-500' : ''}`}
                                placeholder="Skills"
                                maxLength={1000}
                            />
                        ) : (
                            <ul className="list-disc pl-5">
                                {resumeData.skills.map((skill, index) => (
                                    <li key={index}>{skill}</li>
                                ))}
                            </ul>
                        )}
                        {errors['skills-null-null'] && (
                            <p className="text-red-500 text-xs mt-1">{errors['skills-null-null']}</p>
                        )}
                    </div>
                </main>
            )}
            <div className="flex justify-between mt-4">
                <button
                    onClick={handleDownload}
                    className="mr-4 px-2 md:px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-600 text-white rounded-full shadow-md hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                >
                    Download Resume
                </button>
                <button
                    onClick={isEditing ? handleSave : handleEditToggle}
                    className="text-primary-600 border border-primary-600 px-2 md:px-4 py-2 bg-gradient-to-r hover:text-white rounded-full shadow-lg hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                >
                    {isEditing ? 'Save Changes' : 'Edit Resume'}
                </button>
            </div>
        </div>
    );
};

export default ResumePreview;
