import React from 'react';
import { Link } from 'react-router-dom';
const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-gray-50 p-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <Link to="/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
            <img src="/images/logo.png" className="h-8" alt="Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Interview.AI</span>
          </Link>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li className="me-4 md:me-6">
              <Link to="/about" className="hover:underline">About</Link>
            </li>
            <li className="me-4 md:me-6">
              <Link to="#" className="hover:underline">Privacy Policy</Link>
            </li>
            <li className="me-4 md:me-6">
              <Link to="#" className="hover:underline">Licensing</Link>
            </li>
            <li>
              <Link to="/contact" className="hover:underline">Contact</Link>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
        Copyright © {currentYear} <Link to="/" className="hover:underline">GenAI Box™</Link>. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
