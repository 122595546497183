import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

const Question = ({ item, index, toggleAnswer }) => (
    <div
        className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white hover:bg-gray-50 transition duration-300 cursor-pointer"
        onClick={() => toggleAnswer(index)}
    >
        <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold text-gray-700">
                Q{index + 1}: {item.question}
            </h3>
            <FaChevronDown className={`w-5 h-5 text-gray-500 transition-transform ${item.open ? 'transform rotate-180' : ''}`} />
        </div>
        {item.open && (
            <div className="mt-2 p-2 border-t border-gray-200">
                <p className="text-gray-600 mt-2">A: {item.answer}</p>
            </div>
        )}
    </div>
);

export default Question;
