import axiosInstance, { axiosInstanceWithMultipart } from '../../Common/axiosInstance';
import {
    CREATE_JOB_LOADING,
    CREATE_JOB_SUCCESS,
    CREATE_JOB_FAIL,

    JOB_LIST_LOADING,
    JOB_LIST_SUCCESS,
    JOB_LIST_FAIL,

    JOB_DETAIL_LOADING,
    JOB_DETAIL_SUCCESS,
    JOB_DETAIL_FAIL,

    UPDATE_JOB_LOADING,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAIL, 
} from '../constants/jobConstants';
import { toast } from 'react-toastify';

export const createJob = (jobData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_JOB_LOADING }); 
        const config = axiosInstanceWithMultipart();
        const { data } = await axiosInstance.post(`/job/create-job/`, jobData, config);

        dispatch({
            type: CREATE_JOB_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: CREATE_JOB_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const getJobList = () => async (dispatch) => {
    try {
        dispatch({ type: JOB_LIST_LOADING });

        const cachedData = localStorage.getItem('jobList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: JOB_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('jobList');
            }
        }

        const { data } = await axiosInstance.get(`/job/jobs/`);
        
        localStorage.setItem('jobList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: JOB_LIST_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: JOB_LIST_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const fetchJobDetails = (pk) => async (dispatch) => {
    try {
        dispatch({ type: JOB_DETAIL_LOADING }); 
        const { data } = await axiosInstance.get(`/job/job-details/${pk}/`);
        dispatch({
            type: JOB_DETAIL_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: JOB_DETAIL_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const updateJob = (jobId, jobData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_JOB_LOADING });

        const config = axiosInstanceWithMultipart();
        const { data } = await axiosInstance.put(`/job/update-job/${jobId}/`, jobData, config);

        dispatch({
            type: UPDATE_JOB_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: UPDATE_JOB_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const deleteJob = (pk) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/job/delete-job/${pk}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};