import React from 'react';
import { FaBars, FaUserCircle, FaBell, FaSearch, FaTh, Fatimes } from 'react-icons/fa';
import { CgMenuLeft } from "react-icons/cg";

import { NavLink } from 'react-router-dom';

const Header = ({ setSidebarOpen }) => {
  return (
    <header className="lg:hidden lg:ms-64 antialiased sticky top-0 z-50 bg-white shadow-md">
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="flex flex-wrap md:border-b pb-2 justify-between items-center">
          <div className="flex justify-start items-center">
            <button
                aria-expanded="true"
                aria-controls="sidebar"
                className="p-2 mr-2 text-gray-700 rounded-lg cursor-pointer lg:hidden focus:outline-none"
                onClick={() => setSidebarOpen(prev => !prev)}
              >
                <CgMenuLeft className="w-5 h-5" />
                <span className="sr-only">Toggle sidebar</span>
            </button>
            <NavLink to="/" className="flex mr-4 lg:hidden">
              <img src="/images/logo.png" className="mr-3 h-8" alt="Logo" />
              <span className="self-center text-2xl  text-gray-800 font-extrabold whitespace-nowrap dark:text-white">
                Interview.AI
              </span>
            </NavLink> 
          </div> 
        </div>
      </nav>
    </header>
  );
};

export default Header;
