import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, googleAuth } from '../../../redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../Common/AuthProvider';
import "../../../style/Style.css";

const SignupPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const { loading, error } = authState;
    const { login, isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1);
        }
    }, [isAuthenticated]);

    const validate = () => {
        const errors = {};
        if (!firstName) {
            errors.firstName = 'First name is required.';
        } else if (firstName.length > 200) {
            errors.firstName = 'First name must be less than 200 characters.';
        }

        if (!lastName) {
            errors.lastName = 'Last name is required.';
        } else if (lastName.length > 200) {
            errors.lastName = 'Last name must be less than 200 characters.';
        }

        if (!email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        } else if (email.length > 200) {
            errors.email = 'Email must be less than 200 characters.';
        }

        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters.';
        } else if (password.length > 200) {
            errors.password = 'Password must be less than 200 characters.';
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            const userData = {
                email,
                password,
                first_name: firstName,
                last_name: lastName,
                role: 4
            };
            try {
                const response = await dispatch(register(userData));
                setIsSubmitting(false);
                if (response) {
                    toast.success('Account created successfully! Please check your email for verification.');
                    navigate('/email-verification', { state: { email: email } });
                }
            } catch (error) {
                setIsSubmitting(false);
                toast.error('An error occurred. Please try again.');
                if (error.response && error.response.data && error.response.data.email) {
                    setErrors({ general: error.response.data.email[0] });
                } else {
                    setErrors({ general: 'An error occurred. Please try again.' });
                }
            }
        }
    };

    const handleGoogleSuccess = async (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const userData = {
            email: decoded.email,
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            id_token: token,
            role: 4
        };
        await dispatch(googleAuth(userData)).then((res) => {
            if (res && res.access) {
                login(res);
            }
        });
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Create your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Or <Link to="/signin" className="font-medium text-primary-600 hover:text-primary-500">sign in to your account</Link>
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white mx-4 md:mx-0 py-8 px-4 shadow rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.firstName ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                                placeholder="First Name"
                                maxLength="200"
                            />
                            {errors.firstName && <p className="text-red-500 text-xs mt-2">{errors.firstName}</p>}
                        </div>

                        <div>
                            <input
                                id="lastName"
                                name="lastName"
                                type="text"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.lastName ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                                placeholder="Last Name"
                                maxLength="200"
                            />
                            {errors.lastName && <p className="text-red-500 text-xs mt-2">{errors.lastName}</p>}
                        </div>

                        <div>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.email ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                                placeholder="Email"
                                maxLength="200"
                            />
                            {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                        </div>

                        <div>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.password ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                                placeholder="Password"
                                maxLength="200"
                            />
                            {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className={`mt-5 tracking-wide font-semibold text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-primary-600 to-primary-500 hover:from-primary-700 hover:to-primary-600 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600'}`}
                                disabled={isSubmitting}
                            >
                                <svg
                                    className="w-6 h-6 -ml-2"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                                    <circle cx="8.5" cy="7" r="4" />
                                    <path d="M20 8v6M23 11h-6" />
                                </svg>
                                <span className="ml-3">Sign Up</span>
                            </button>
                        </div>
                    </form>
                    <div className="text-center mt-6">
                        <p className="font-bold text-gray-500">OR</p>
                    </div>
                    <div className='mx-auto justify-center text-center ml-4 md:ml-20 mt-4'>
                        <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                onFailure={handleGoogleFailure}
                            />
                        </GoogleOAuthProvider>
                    </div>
                    {loading && <p className="mt-4 text-gray-600">Loading...</p>}
                    {error && (
                        <div className="mt-3 text-red-500">
                            <p>{error}</p>
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default SignupPage;
