export const INTERVIEW_TYPE_LOADING = 'INTERVIEW_TYPE_LOADING'
export const INTERVIEW_TYPE_SUCCESS = 'INTERVIEW_TYPE_SUCCESS'
export const INTERVIEW_TYPE_FAIL = 'INTERVIEW_TYPE_FAIL'

export const CREATE_QNA_LOADING = 'CREATE_QNA_LOADING'
export const CREATE_QNA_SUCCESS = 'CREATE_QNA_SUCCESS'
export const CREATE_QNA_FAIL = 'CREATE_QNA_FAIL'

export const QNA_DETAILS_LOADING = 'QNA_DETAILS_LOADING'
export const QNA_DETAILS_SUCCESS = 'QNA_DETAILS_SUCCESS'
export const QNA_DETAILS_FAIL = 'QNA_DETAILS_FAIL'

export const QNA_LIST_LOADING = 'QNA_LIST_LOADING'
export const QNA_LIST_SUCCESS = 'QNA_LIST_SUCCESS'
export const QNA_LIST_FAIL = 'QNA_LIST_FAIL'

export const CREATE_LIVEQNA_LOADING = 'CREATE_LIVEQNA_LOADING'
export const CREATE_LIVEQNA_SUCCESS = 'CREATE_LIVEQNA_SUCCESS'
export const CREATE_LIVEQNA_FAIL = 'CREATE_LIVEQNA_FAIL'

export const LIVEQNA_DETAILS_LOADING = 'LIVEQNA_DETAILS_LOADING'
export const LIVEQNA_DETAILS_SUCCESS = 'LIVEQNA_DETAILS_SUCCESS'
export const LIVEQNA_DETAILS_FAIL = 'LIVEQNA_DETAILS_FAIL'

export const LIVEQNA_LIST_LOADING = 'LIVEQNA_LIST_LOADING'
export const LIVEQNA_LIST_SUCCESS = 'LIVEQNA_LIST_SUCCESS'
export const LIVEQNA_LIST_FAIL = 'LIVEQNA_LIST_FAIL'

export const GET_RESULT_LOADING = 'GET_RESULT_LOADING'
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS'
export const GET_RESULT_FAIL = 'GET_RESULT_FAIL' 