import React from 'react';
import { FaRegComments } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Divider = () => {
  return (
    <div className="relative py-2 md:py-10 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-300"></div>
      </div>
      <div className="relative flex justify-center">
        <div className="bg-white px-6 py-6 rounded-full shadow-md flex flex-col sm:flex-row items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
          <FaRegComments className="text-primary-600" size={32} />
          <p className="text-center sm:text-left text-lg font-medium text-gray-800">
            Let's work together to achieve your goals.{' '}
            <Link to="/contact" className="text-primary-600 underline">
              Got a question? Reach out!
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Divider;
