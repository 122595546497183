import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
      <div className="w-full mx-auto max-w-screen-xl p-4 flex justify-center items-center">
        <span className="text-sm text-gray-500 dark:text-gray-400">
          © {currentYear} <a href="#" className="hover:underline">Interview.AI</a>. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
