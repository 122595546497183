import React from 'react';

const convertToPercentage = (value) => {
    return value <= 1 ? value * 100 : value;
};

const getBackgroundColor = (value) => {
    if (value === 100) return 'bg-green-100';
    if (value >= 50) return 'bg-yellow-100';
    return 'bg-red-100';
};

const getColorClass = (value) => {
    if (value === 100) return 'text-green-800 bg-green-100 font-semibold';
    if (value >= 50) return 'text-yellow-800 bg-yellow-100 font-semibold';
    return 'text-red-800 bg-red-100 font-semibold';
};

const QuestionAnswer = ({ question, answer, evaluation, index }) => (
    <details key={index} className={`bg-gray-50 p-6 rounded-lg shadow-md transition duration-200 ease-in-out transform hover:scale-55`}>
        <summary className="text-lg font-semibold text-gray-800 cursor-pointer">
            {question}
        </summary>
        <div className="mt-4">
            <h3 className="text-md font-semibold text-gray-800">Answer:</h3>
            <p className="text-gray-700 mb-4">{answer}</p>
            <h3 className="text-md font-semibold text-gray-800 mb-2">Evaluation:</h3>
            {evaluation ? (
                <>
                    <div className="md:flex md:space-x-4 mb-4">
                        <p className={`px-3 py-1 mb-2 md:mb-0 rounded-full ${getBackgroundColor(convertToPercentage(evaluation.correctness))} ${getColorClass(convertToPercentage(evaluation.correctness))}`}>
                            Correctness: {convertToPercentage(evaluation.correctness)}%
                        </p>
                        <p className={`px-3 py-1 mb-2 md:mb-0 rounded-full ${getBackgroundColor(convertToPercentage(evaluation.completeness))} ${getColorClass(convertToPercentage(evaluation.completeness))}`}>
                            Completeness: {convertToPercentage(evaluation.completeness)}%
                        </p>
                        <p className={`px-3 py-1 mb-2 md:mb-0 rounded-full ${getBackgroundColor(convertToPercentage(evaluation.relevance))} ${getColorClass(convertToPercentage(evaluation.relevance))}`}>
                            Relevance: {convertToPercentage(evaluation.relevance)}%
                        </p>
                    </div>
                    <p className="px-4 py-3 rounded-lg bg-primary-100 text-primary-800 font-semibold shadow-inner">
                        <span className="underline">Feedback</span>: {evaluation.feedback}
                    </p>
                </>
            ) : (
                <p className="text-gray-600">Evaluation not available.</p>
            )}
        </div>
    </details>
);

export default QuestionAnswer;
