import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const NavigationButtons = ({ currentQuestionIndex, totalQuestions, handlePreviousQuestion, handleNextQuestion, handleSubmitAnswers }) => (
    <div className="flex justify-between mt-4">
        <button
            onClick={handlePreviousQuestion}
            className="flex px-3 md:px-4 py-1 md:py-2 bg-gray-500 text-white font-semibold rounded-full shadow-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all duration-300"
        >
            <FaChevronLeft size={17} className="mt-1" /> Previous
        </button>
        {currentQuestionIndex < totalQuestions - 1 ? (
            <button
                onClick={handleNextQuestion}
                className="flex px-3 md:px-4 py-1 md:py-2 bg-primary-500 text-white font-semibold rounded-full shadow-lg hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
            >
                Next <FaChevronRight size={17} className="mt-1" />
            </button>
        ) : (
            <button
                onClick={handleSubmitAnswers}
                className="px-2 md:px-4 py-1 md:py-2 bg-green-500 text-white font-semibold rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-300"
            >
                Submit Answers
            </button>
        )}
    </div>
);

export default NavigationButtons;
