import React from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";
import "../../../style/Style.css"

const HomePageHero = () => {
  return (
    <div className="relative bg-primary-50 text-gray-900 overflow-hidden">
      <div className="relative mx-auto max-w-7xl px-6 py-16 sm:pt-32 sm:pb-20 lg:pt-32 lg:pb-20">
        <Transition
          show={true}
          appear={true}
          enter="transform transition duration-700"
          enterFrom="opacity-0 translate-y-10"
          enterTo="opacity-100 translate-y-0"
        >
          <div className="text-center">
            <h1 className="text-4xl font-bold sm:text-5xl md:text-6xl">
              <span className="block">Master Your Next Interview</span>
              <span className="block md:mt-4">with AI-Powered <span className="relative whitespace-nowrap text-primary-600">
                <svg
                  aria-hidden="true"
                  viewBox="0 0 418 42"
                  className="absolute left-0 top-2/3 h-[0.58em] w-full fill-primary-300/70"
                  preserveAspectRatio="none"
                >
                  <path d="M0,30 Q150,20 300,30 T418,30" fill="none" stroke="currentColor" strokeWidth="4" />
                </svg>
                <span className="relative">Interview Prep</span>
              </span></span>
            </h1>
            <p className="mt-6 max-w-xl text-gray-500 font-semibold mx-auto text-lg sm:text-xl">
              Enhance your interview performance with our AI-driven platform. Practice with personalized questions, receive real-time feedback, and refine your resume effortlessly.
            </p>
            <div className="mt-12 md:mt-24 flex flex-col lg:flex-row justify-center items-center gap-12">
              <div className="bg-white shadow-lg rounded-lg p-6 max-w-xl">
                <img src="./images/live-interview.png" alt="Interview" className="object-cover rounded-lg" />
              </div>
              <div className="p-6 max-w-lg text-left">
                <h2 className="text-2xl font-bold mb-4 text-gray-700 sm:text-3xl">Boost Your Interview Skills</h2>
                <p className="mb-4 text-gray-500 font-semibold">
                  Practice with AI-driven interview scenarios designed to help you succeed. Our platform offers:
                </p>
                <ul className="custom-list text-gray-500 font-semibold space-y-2">
                  <li>Realistic mock interviews</li>
                  <li>Instant, actionable feedback</li>
                  <li>Customized question sets</li>
                  <li>Detailed performance analytics</li>
                </ul>
                <div className="mt-4 flex items-center">
                  <div className="flex -space-x-2">
                    <img className="w-10 h-10 rounded-full border-2 border-white" src="https://randomuser.me/api/portraits/men/54.jpg" alt="User 3" />
                    <img className="w-10 h-10 rounded-full border-2 border-white" src="https://randomuser.me/api/portraits/men/55.jpg" alt="User 4" />
                    <img className="w-10 h-10 rounded-full border-2 border-white" src="https://randomuser.me/api/portraits/women/56.jpg" alt="User 5" />
                  </div>
                  <div className="ml-16 md:ml-4 text-gray-700 font-semibold">
                    Trusted by over <span className="font-bold text-primary-600">2,000+</span> job seekers
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 flex justify-center gap-4">
              <Link
                to="/signup"
                className="inline-flex items-center justify-center rounded-full bg-gradient-to-r from-primary-500 to-primary-700 py-3 px-8 text-base font-medium text-white hover:from-primary-700 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 transition-transform transform hover:scale-105"
              >
                Try for free <FaArrowRight className="ml-2" />
              </Link> 
            </div>
          </div>
        </Transition>
      </div> 
    </div>
  );
};

export default HomePageHero;
