import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import "../../../style/template.css";
import { createResume, getResumeTemplates } from '../../../redux/actions/resumeActions';
import TemplateTile from './TemplateTile';
import Button from '../../../Components/Button';
import Loading from '../../Components/Loading';

const CreateResume = () => {
    const dispatch = useDispatch();
    const [jobType, setJobType] = useState([]);
    const [resumeTemplates, setResumeTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [initData, setInitData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [errors, setErrors] = useState({}); 
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getResumeTemplates()).then((res) => {
            if (res) {
                setResumeTemplates(res.resume_templates);
                setJobType(res.jobs);
                if (res.jobs.length > 0) {
                    setInitData({ selectJob: res.jobs[0].id });
                }
                if (res.resume_templates.length > 0) {
                    setSelectedTemplate(res.resume_templates[0]);
                }
            }
        });
    }, [dispatch]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setInitData({ ...initData, [id]: value });
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return; // Prevent multiple submissions
        setIsLoading(true);
        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const formData = {
            user: userDetail.id,
            id: null,
            job: initData.selectJob,
            resume_template: selectedTemplate.id,
        };
        setInitData({});

        try {
            const fakeProgress = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(fakeProgress);
                        return 100;
                    }
                    return prevProgress + 1; // Adjust increment as needed
                });
            }, 200); // Adjust interval as needed

            const res = await dispatch(createResume(formData));
            if (res) {
                localStorage.removeItem('resumeList');
                if(selectedTemplate.id === 1) {
                    navigate('/preview-resume-1', { state: { id: res.id }});
                } else if(selectedTemplate.id === 2) {
                    navigate('/preview-resume-2', { state: { id: res.id }});
                }
            }
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
            <main className="w-full max-w-7xl p-4 md:p-6">
                <div className="text-center mb-6">
                    <h1 className="text-2xl md:text-4xl font-bold text-gray-700">Create Resume for Interview</h1>
                    <p className="text-gray-500">Select a job and a resume template!</p>
                </div>
                <div className="bg-white p-4 md:p-8 rounded-lg shadow-lg mb-6">
                    {isLoading ? (
                        <Loading progress={progress} message="Creating your resume..." />
                    ) : (
                        <form onSubmit={handleSubmit} className="space-y-2">
                            <label className="block text-gray-700 font-semibold" htmlFor="selectJob">Select Job</label>
                            <div className="grid gap-2 grid-cols-1">
                                <div className="">
                                    <select
                                        id="selectJob"
                                        value={initData.selectJob || ''}
                                        onChange={handleInputChange}
                                        className={`w-full px-4 py-2 text-gray-500 font-normal border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400 ${errors.selectJob ? 'border-red-500' : 'border-gray-200'}`}
                                        required
                                    >
                                        {jobType && jobType.map((type) => (
                                            <option key={type.id} value={type.id}>
                                                {type.title}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.selectJob && <p className="text-red-500 text-xs mt-2">{errors.selectJob}</p>}
                                </div>
                            </div>
                            <label className="block text-gray-700 font-semibold pt-4" htmlFor="selectResumeTemplate">Select Resume Template</label>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {resumeTemplates && resumeTemplates.map((template) => (
                                    <TemplateTile
                                        key={template.id}
                                        template={template}
                                        isSelected={selectedTemplate && selectedTemplate.id === template.id}
                                        onSelect={handleTemplateSelect}
                                    />
                                ))}
                            </div>
                            <div className="flex justify-center py-4 md:py-0 mt-6">
                                <Button type="submit" disabled={isLoading}>
                                    {isLoading ? 'Submitting...' : 'Create Now'}
                                </Button>
                            </div>
                        </form>
                    )}
                </div>
                <ToastContainer />
            </main>
        </div>
    );
};

export default CreateResume;
