import React from 'react';
import { FaRegLightbulb, FaUsers, FaChalkboardTeacher, FaClipboardList } from 'react-icons/fa';

export const AboutUsSection = () => {
  return (
    <div className="bg-gray-50 py-12 md:py-24 px-4 sm:px-6 lg:px-16">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <p className="md:mt-4 text-xl text-gray-600 mx-auto max-w-3xl">
            At Interview.AI, we leverage advanced artificial intelligence to help job seekers master their interview skills. Our platform provides realistic interview simulations, instant feedback, and tailored resume-building tools to ensure you are fully prepared to land your dream job.
          </p>
        </div>

        <div className="mt-12 md:mt-20">
          <div className="flex justify-center">
            <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
              <div className="text-center p-6 bg-white rounded-2xl shadow-md">
                <FaRegLightbulb className="mx-auto h-8 w-8 text-primary-600" />
                <h3 className="mt-6 text-xl font-semibold text-gray-900">Innovative Technology</h3>
                <p className="mt-2 text-base text-gray-500">Our AI-driven platform offers cutting-edge solutions for effective interview preparation.</p>
              </div>
              <div className="text-center p-6 bg-white rounded-2xl shadow-md">
                <FaUsers className="mx-auto h-8 w-8 text-primary-600" />
                <h3 className="mt-6 text-xl font-semibold text-gray-900">User-Centric Design</h3>
                <p className="mt-2 text-base text-gray-500">Designed with job seekers in mind, our platform is intuitive and easy to use.</p>
              </div>
              <div className="text-center p-6 bg-white rounded-2xl shadow-md">
                <FaChalkboardTeacher className="mx-auto h-8 w-8 text-primary-600" />
                <h3 className="mt-6 text-xl font-semibold text-gray-900">Expert Guidance</h3>
                <p className="mt-2 text-base text-gray-500">Get insights and tips from industry experts to ace your interviews.</p>
              </div>
              <div className="text-center p-6 bg-white rounded-2xl shadow-md">
                <FaClipboardList className="mx-auto h-8 w-8 text-primary-600" />
                <h3 className="mt-6 text-xl font-semibold text-gray-900">Comprehensive Resources</h3>
                <p className="mt-2 text-base text-gray-500">Access a wide range of resources, including resume building and interview tips.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
