import React from 'react';

const Testimonials = () => {
  return (
    <section className="bg-gray-50 text-gray-800">
      <div className="container px-0 md:px-6 pt-12 pb-16 mx-auto">
        <div className="grid items-center">
          <div className="max-w-2xl mx-auto my-8 space-y-4 text-center">
            <p className="max-w-max text-center mx-auto py-1 px-4 rounded-full mb-1 font-sans font-semibold tracking-tight text-primary-600 bg-primary-50 sm:leading-none">
              TESTIMONIALS
            </p>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800">Our Users' Success Stories</h2>
            <p className="text-gray-600">Discover how our platform has helped job seekers land their dream jobs. Here are some of their inspiring stories.</p>
          </div>
          <div className="p-6">
            <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div className="p-6 rounded-lg shadow-md bg-white">
                <p className="text-gray-700">"Thanks to this platform, I was able to ace my interviews with confidence. The practice sessions were incredibly realistic and helped me prepare thoroughly."</p>
                <div className="flex items-center mt-4 space-x-4">
                  <img src="https://randomuser.me/api/portraits/men/32.jpg" alt="Client" className="w-12 h-12 bg-center bg-cover rounded-full" />
                  <div>
                    <p className="text-lg font-semibold">Leroy Jenkins</p>
                    <p className="text-sm text-gray-600">Software Engineer at Tech Solutions</p>
                  </div>
                </div>
              </div>
              <div className="p-6 rounded-lg shadow-md bg-white">
                <p className="text-gray-700">"The AI-driven feedback was a game-changer for me. I could see where I was going wrong and improve my answers. I landed a job within a month of using this platform."</p>
                <div className="flex items-center mt-4 space-x-4">
                  <img src="https://randomuser.me/api/portraits/women/45.jpg" alt="Client" className="w-12 h-12 bg-center bg-cover rounded-full" />
                  <div>
                    <p className="text-lg font-semibold">Jane Doe</p>
                    <p className="text-sm text-gray-600">HR Manager at Global Corp</p>
                  </div>
                </div>
              </div>
              <div className="p-6 rounded-lg shadow-md bg-white">
                <p className="text-gray-700">"The customized questions were spot-on with what I encountered in my actual interviews. The thorough preparation helped me secure a top position at a leading firm."</p>
                <div className="flex items-center mt-4 space-x-4">
                  <img src="https://randomuser.me/api/portraits/men/43.jpg" alt="Client" className="w-12 h-12 bg-center bg-cover rounded-full" />
                  <div>
                    <p className="text-lg font-semibold">John Smith</p>
                    <p className="text-sm text-gray-600">Marketing Director at Creative Agency</p>
                  </div>
                </div>
              </div>
              <div className="p-6 rounded-lg shadow-md bg-white">
                <p className="text-gray-700">"This platform's comprehensive approach to interview preparation was exactly what I needed. The detailed analytics gave me insights that I couldn't have found elsewhere."</p>
                <div className="flex items-center mt-4 space-x-4">
                  <img src="https://randomuser.me/api/portraits/women/47.jpg" alt="Client" className="w-12 h-12 bg-center bg-cover rounded-full" />
                  <div>
                    <p className="text-lg font-semibold">Emily Johnson</p>
                    <p className="text-sm text-gray-600">Product Manager at Innovatech</p>
                  </div>
                </div>
              </div>
              <div className="p-6 rounded-lg shadow-md bg-white">
                <p className="text-gray-700">"I was able to prepare effectively for my interviews with the realistic mock scenarios provided. This platform is a must for anyone serious about getting their dream job."</p>
                <div className="flex items-center mt-4 space-x-4">
                  <img src="https://randomuser.me/api/portraits/men/50.jpg" alt="Client" className="w-12 h-12 bg-center bg-cover rounded-full" />
                  <div>
                    <p className="text-lg font-semibold">Michael Clark</p>
                    <p className="text-sm text-gray-600">Finance Analyst at Business Inc.</p>
                  </div>
                </div>
              </div>
              <div className="p-6 rounded-lg shadow-md bg-white">
                <p className="text-gray-700">"The personalized feedback and detailed analytics were incredibly helpful. I felt fully prepared and confident walking into my interviews."</p>
                <div className="flex items-center mt-4 space-x-4">
                  <img src="https://randomuser.me/api/portraits/women/52.jpg" alt="Client" className="w-12 h-12 bg-center bg-cover rounded-full" />
                  <div>
                    <p className="text-lg font-semibold">Sarah Lee</p>
                    <p className="text-sm text-gray-600">Project Manager at Solutions Ltd.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
