export const INTERVIEW_TYPE_LOADING = 'INTERVIEW_TYPE_LOADING'
export const INTERVIEW_TYPE_SUCCESS = 'INTERVIEW_TYPE_SUCCESS'
export const INTERVIEW_TYPE_FAIL = 'INTERVIEW_TYPE_FAIL'

export const CREATE_JOB_LOADING = 'CREATE_JOB_LOADING'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_FAIL = 'CREATE_JOB_FAIL'

export const JOB_DETAIL_LOADING = 'JOB_DETAIL_LOADING'
export const JOB_DETAIL_SUCCESS = 'JOB_DETAIL_SUCCESS'
export const JOB_DETAIL_FAIL = 'JOB_DETAIL_FAIL'

export const JOB_LIST_LOADING = 'JOB_LIST_LOADING'
export const JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS'
export const JOB_LIST_FAIL = 'JOB_LIST_FAIL'

export const UPDATE_JOB_LOADING = 'UPDATE_JOB_LOADING'
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS'
export const UPDATE_JOB_FAIL = 'UPDATE_JOB_FAIL'  