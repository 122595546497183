export const RESUME_TEMPLATES_LOADING = 'RESUME_TEMPLATES_LOADING'
export const RESUME_TEMPLATES_SUCCESS = 'RESUME_TEMPLATES_SUCCESS'
export const RESUME_TEMPLATES_FAIL = 'RESUME_TEMPLATES_FAIL'

export const CREATE_RESUME_LOADING = 'CREATE_RESUME_LOADING'
export const CREATE_RESUME_SUCCESS = 'CREATE_RESUME_SUCCESS'
export const CREATE_RESUME_FAIL = 'CREATE_RESUME_FAIL' 

export const RESUME_LIST_LOADING = 'RESUME_LIST_LOADING'
export const RESUME_LIST_SUCCESS = 'RESUME_LIST_SUCCESS'
export const RESUME_LIST_FAIL = 'RESUME_LIST_FAIL'

export const RESUME_DETAILS_LOADING = 'RESUME_DETAILS_LOADING'
export const RESUME_DETAILS_SUCCESS = 'RESUME_DETAILS_SUCCESS'
export const RESUME_DETAILS_FAIL = 'RESUME_DETAILS_FAIL'

export const UPDATE_RESUME_LOADING = 'UPDATE_RESUME_LOADING'
export const UPDATE_RESUME_SUCCESS = 'UPDATE_RESUME_SUCCESS'
export const UPDATE_RESUME_FAIL = 'UPDATE_RESUME_FAIL'