import React from 'react';

const FAQ = () => {
  const faqs = [
    {
      question: "What is the main purpose of this platform?",
      answer: "Our platform is designed to empower job seekers by providing comprehensive tools for interview preparation and resume creation. Using AI-driven technology, we offer realistic interview practice sessions, detailed feedback on responses, and professional resume-building assistance. This holistic approach helps users to be well-prepared, confident, and market-ready for their desired job roles."
    },
    {
      question: "How does the AI interview preparation work?",
      answer: "The AI interview preparation works by analyzing the job description and your resume to generate relevant and challenging interview questions. You can practice these questions in a simulated interview environment. The AI then evaluates your responses based on various criteria, such as clarity, relevance, and confidence, and provides you with constructive feedback. This process helps you identify your strengths and areas for improvement, ensuring you are fully prepared for real-world interviews."
    },
    {
      question: "Can I customize the interview questions?",
      answer: "Yes, our platform allows you to customize the interview questions to match specific job roles or industries. You can select from a wide range of pre-defined questions or create your own to tailor your practice sessions. This customization ensures that the practice you get is directly relevant to the positions you are applying for, enhancing the effectiveness of your preparation."
    },
    {
      question: "How does the resume creation tool work?",
      answer: "Our resume creation tool uses advanced algorithms to analyze your previous work experiences, skills, and job descriptions to generate a professional resume. The tool provides suggestions for optimizing your resume with industry-specific keywords and formats it to meet current standards. This ensures that your resume stands out to recruiters and passes through applicant tracking systems (ATS) successfully."
    },
    {
      question: "Is my personal data secure on this platform?",
      answer: "Absolutely. We take your privacy and data security very seriously. All personal data and audio files are encrypted and stored securely. We adhere to strict data protection regulations and never share your information with third parties without your explicit consent. Our privacy policy details all the measures we take to protect your data."
    },
    {
      question: "Can I track my progress over time?",
      answer: "Yes, our platform provides comprehensive analytics and progress tracking features. You can monitor your improvement over time through detailed reports and performance charts. These insights help you understand your development areas and track how your interview skills are evolving with continued practice."
    },
    {
      question: "What kind of feedback does the AI provide?",
      answer: "The AI provides detailed feedback on multiple aspects of your interview responses, including clarity, relevance, completeness, and delivery. It highlights specific areas where you excel and offers constructive suggestions for improvement. This targeted feedback helps you refine your answers, improve your communication skills, and boost your overall interview performance."
    },
    {
      question: "Is there a community or support available?",
      answer: "Yes, we offer a vibrant community forum where users can connect, share experiences, and exchange tips. Our support team is also readily available to assist you with any questions or issues. Whether you need technical support or advice on interview preparation, we are here to help you succeed."
    }
  ];

  return (
    <section className="bg-gray-50 text-gray-800 md:pt-12 pb-16">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <p className="max-w-max text-center mx-auto py-1 px-4 rounded-full mb-1 font-sans font-semibold tracking-tight text-primary-600 bg-primary-50 sm:leading-none">
            FAQ
          </p>
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800">Frequently Asked Questions</h2>
          <p className="text-gray-600 mt-2">How can we help you?</p>
        </div>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <details key={index} className="w-full md:max-w-4xl mx-auto p-4 border border-gray-200 rounded-lg shadow-sm bg-white">
              <summary className="cursor-pointer text-lg font-semibold text-gray-600">{faq.question}</summary>
              <p className="mt-4 text-gray-600">{faq.answer}</p>
            </details>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
