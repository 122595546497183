import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";

const CallToAction = () => {
  return (
    <div className="p-4 md:p-12 bg-gray-50"> 
      <div className="relative rounded-2xl px-6 py-10 bg-primary-50 overflow-hidden shadow-md sm:px-12 sm:py-20"> 
        <div className="relative flex flex-col items-center text-center">
          <h1 className="text-4xl font-bold text-gray-800 md:text-5xl">
            Master Your Job Interviews
          </h1>
          <p className="mt-6 mx-auto max-w-2xl text-lg text-gray-700">
            Join thousands of job seekers who have successfully landed their dream jobs with our AI-powered interview preparation and resume building tools.
          </p>
          <div className="flex justify-center gap-6 mt-10">
            <Link to="/signup" className="flex items-center justify-center rounded-full bg-gradient-to-r from-primary-500 to-primary-700 py-3 px-8 text-base font-medium text-white hover:from-primary-700 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 transition-transform transform hover:scale-105">
              <span className="relative text-base font-semibold text-white">Get Started Now</span><FaArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
