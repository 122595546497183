import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { resendVerifyEmail } from "../../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const EmailVerification = ({ email }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const emailFromState = location.state?.email || email;

  useEffect(() => {
    if (!emailFromState) {
      navigate("/signin");
    }
  }, [emailFromState, navigate]);

  const handleResendVerification = () => {
    setIsSubmitting(true);
    dispatch(resendVerifyEmail(emailFromState)).then((res) => {
      setIsSubmitting(false);
      if (res) {
        toast.success('Verification link has been sent to your email.');
      } else {
        toast.error('Failed to send verification link. Please try again.');
      }
    }).catch(() => {
      setIsSubmitting(false);
      toast.error('Failed to send verification link. Please try again.');
    });
  };

  return (
    <div className="flex items-center justify-center flex-col min-h-screen bg-gray-50">
      <section className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg">
        <div className="h-[300px] bg-primary-600 px-12 mb-12 w-full text-white flex items-center justify-center flex-col gap-5 rounded-t-lg">
          <div className="flex items-center gap-3">
            <div className="w-10 h-[1px] bg-white"></div>
            <EmailIcon />
            <div className="w-10 h-[1px] bg-white"></div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-center text-sm sm:text-xl tracking-widest font-normal">
              ACTION REQUIRED
            </div>
            <div className="text-center text-xl sm:text-3xl tracking-wider font-bold capitalize">
              Verify your E-mail Address
            </div>
            <p className="text-center">
              Please check your email for the verification link.
            </p>
            <button 
              onClick={handleResendVerification} 
              className={`bg-white my-4 text-primary-600 mx-10 px-5 py-2 rounded-lg font-semibold tracking-wider hover:bg-gray-200 transition duration-200 ${isSubmitting ? 'cursor-not-allowed' : ''}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Resend Link'}
            </button>
          </div>
        </div>
        <div className="px-12 pb-12 text-gray-800">
          <p className="text-center text-lg">
            We have sent a verification link to your email address. Please click on the link in the email to verify your account. If you did not receive the email, check your spam folder or click the button above to resend the link.
          </p>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default EmailVerification;

const EmailIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
    </svg>
  );
};
