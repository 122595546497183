import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from './reducers/authReducers';
import { loadingReducer } from './reducers/loadingReducer';
import { getLiveQnaDetailsReducer, getQnaDetailsReducer, getResultReducer } from './reducers/qnaReducers';
import { getResumeDetailsReducer } from './reducers/ResumeReducers';

const rootReducer = combineReducers({
    loading: loadingReducer,
    auth: authReducer,
    qnaDetails: getQnaDetailsReducer,
    liveQnaDetails: getLiveQnaDetailsReducer,
    results: getResultReducer,
    resumeDetails: getResumeDetailsReducer,
});

const initialState = {
    auth: { userInfo: null },
    qnaDetails: {},
    liveQnaDetails: {},
    results: {},
    resumeDetails: {},
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
});

export default store;
