import React, { useState } from 'react';
import { FaHome, FaCalendarAlt, FaSuitcase, FaUser, FaCog, FaChartLine, FaFileInvoice, FaUsers, FaTimes } from 'react-icons/fa';
import { IoHomeOutline, IoDocumentsOutline } from "react-icons/io5";
import { TbFileInvoice } from "react-icons/tb";
import { MdDocumentScanner } from "react-icons/md";
import { IoHomeSharp } from "react-icons/io5";
import { FaRegAddressCard } from "react-icons/fa6";
import { AiFillIdcard } from "react-icons/ai";
import { PiChats } from "react-icons/pi";
import { HiDocumentText } from "react-icons/hi";
import { TiDocumentText } from "react-icons/ti";
import { BsSuitcaseLg } from "react-icons/bs";
import { TbLogout } from "react-icons/tb";
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../Common/AuthProvider';

const SideBar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { logout } = useAuth();

  const activeLink = (pathname) => {
    return location.pathname === pathname ? 'bg-primary-600 text-white' : '';
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 1024) { // adjust the width as per your breakpoint
      setSidebarOpen(false);
    }
  };

  return (
    <div>
      <aside id="default-sidebar" className={`fixed px-4 top-0 left-0 z-40 inset-y-0 left-0 bg-white h-screen shadow-lg w-64 transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:translate-x-0 lg:inset-0`}>
        <div className="flex items-center justify-between p-4">
          <NavLink to="/" className="flex items-center" onClick={handleLinkClick}>
            <img src='/images/logo.png' alt="Logo" className="h-8" />
            <span className="self-center text-2xl ml-2 text-gray-800 font-extrabold whitespace-nowrap dark:text-white">
              Interview.AI
            </span>
          </NavLink>
          <button className="lg:hidden focus:outline-none" onClick={() => setSidebarOpen(!sidebarOpen)}>
            <FaTimes />
          </button>
        </div>
        <nav className="flex flex-col justify-between h-full">
          <div className='mt-4 lg:mt-8'>
            <NavLink to="/dashboard" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/dashboard')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <IoHomeOutline size="20" className="mr-3" /> Dashboard
            </NavLink> 
            <NavLink to="/job-list" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/job-list')} ${activeLink('/add-job')} ${activeLink('/edit-job')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <BsSuitcaseLg size="20" className="mr-3" /> Jobs
            </NavLink>
            <NavLink to="/interview-qna" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/interview-qna')} ${activeLink('/create-qna')} ${activeLink('/que-ans')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <FaRegAddressCard  size="20" className="mr-3" /> Interview QNA
            </NavLink>
            <NavLink to="/live-qna" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/live-qna')} ${activeLink('/live-interview-result')} ${activeLink('/live-interview')} ${activeLink('/create-live-qna')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <PiChats size="20" className="mr-3" /> Live Interview
            </NavLink>
            <NavLink to="/resumes" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/resumes')} ${activeLink('/create-resume')} ${activeLink('/preview-resume-1')} ${activeLink('/preview-resume-2')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <TiDocumentText size="20" className="mr-3" /> Resume
            </NavLink> 
          </div>
          <div className="mb-16"> 
            <NavLink to="/profile" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500  text-base/[27px] ${activeLink('/profile')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <FaCog className="mr-3" /> Setting
            </NavLink>
            <NavLink onClick={() => logout()} className={`flex bg-primary-200  mt-1 items-center py-2 px-6 rounded mb-3 text-primary-600 font-semibold text-base/[27px] hover:bg-gray-200 transition-colors duration-300`}>
              <TbLogout size={20} className="font-bold mr-3" /> Logout
            </NavLink>
          </div>
        </nav>
      </aside>
    </div>
  );
};

export default SideBar;
