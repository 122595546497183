import React from 'react';
import TeamSection from './About/TeamSection'; 
import { AboutUsSection } from './About/AboutusSection';
import Divider from './About/Divider'; 
import AboutUsHero from './About/AboutUsHero';
import CallToAction from './Home/Calltoaction';

const About = () => {
  return (
    <> 
      <AboutUsHero />
      <AboutUsSection />
      <Divider />
      <TeamSection />
      <CallToAction />
    </>
  );
};

export default About;
