import React, { useState } from 'react';
import { FaMicrophone, FaStop, FaVolumeUp } from 'react-icons/fa';

const AnswerInput = ({ answer, isListening, handleInputChange, handleStartListening, handleStopListening, handleSpeakAnswer }) => {
    const [error, setError] = useState('');

    const handleChange = (value) => {
        const maxLength = 500; // Set your max character length here

        if (value.length > maxLength) {
            setError(`Answer must be less than ${maxLength} characters.`);
        } else {
            setError('');
            handleInputChange(value);
        }
    };
    return (
    <div>
        <textarea
            className={`w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 ${error ? 'border-red-500' : ''}`}
            rows="4"
            value={answer}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Type your answer here..."
        />
        {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
        <div className="flex items-center mt-4 space-x-4">
            <button
                onClick={isListening ? handleStopListening : handleStartListening}
                className={`px-4 py-2 text-white font-semibold rounded-full shadow-lg transition-all duration-300 ${isListening ? 'bg-red-500 hover:bg-red-600' : 'bg-primary-500 hover:bg-primary-600'}`}
                title={isListening ? "Stop Listening" : "Speak Answer"}
            >
                {isListening ? <FaStop /> : <FaMicrophone />}
            </button>
            <button
                onClick={handleSpeakAnswer}
                className="px-4 py-2 bg-green-500 text-white font-semibold rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-300"
                title="Listen to Answer"
            >
                <FaVolumeUp />
            </button>
        </div>
    </div>
    );
};

export default AnswerInput;
