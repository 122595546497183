import axiosInstance from '../../Common/axiosInstance';
import {  
    INTERVIEW_TYPE_LOADING,
    INTERVIEW_TYPE_SUCCESS,
    INTERVIEW_TYPE_FAIL,

    CREATE_QNA_LOADING,
    CREATE_QNA_SUCCESS,
    CREATE_QNA_FAIL,

    QNA_LIST_LOADING,
    QNA_LIST_SUCCESS,
    QNA_LIST_FAIL,

    QNA_DETAILS_LOADING,
    QNA_DETAILS_SUCCESS,
    QNA_DETAILS_FAIL,

    CREATE_LIVEQNA_LOADING,
    CREATE_LIVEQNA_SUCCESS,
    CREATE_LIVEQNA_FAIL,

    LIVEQNA_DETAILS_LOADING,
    LIVEQNA_DETAILS_SUCCESS,
    LIVEQNA_DETAILS_FAIL,

    LIVEQNA_LIST_LOADING,
    LIVEQNA_LIST_SUCCESS,
    LIVEQNA_LIST_FAIL,

    GET_RESULT_LOADING,
    GET_RESULT_SUCCESS,
    GET_RESULT_FAIL,
} from '../constants/qnaConstants';
import { toast } from 'react-toastify';

export const getInterviewType = () => async (dispatch) => {
    try {
        dispatch({ type: INTERVIEW_TYPE_LOADING });

        // const cachedData = localStorage.getItem('interveiwType');
        // if (cachedData) {
        //     const parsedData = JSON.parse(cachedData);
        //     const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        //     const currentTime = new Date().getTime();

        //     if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
        //         dispatch({
        //             type: INTERVIEW_TYPE_SUCCESS,
        //             payload: parsedData.data,
        //         });
        //         return parsedData.data;
        //     } else {
        //         localStorage.removeItem('interveiwType');
        //     }
        // }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/job/interview-types/${userDetail.id}/`);  
        localStorage.setItem('interveiwType', JSON.stringify({ data, timestamp: new Date().getTime() }));      
        dispatch({
            type: INTERVIEW_TYPE_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: INTERVIEW_TYPE_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
    }
} 

export const createQnA = (qnaData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_QNA_LOADING });
        const { data } = await axiosInstance.post('/job/create-interview-qna/', qnaData);

        dispatch({
            type: CREATE_QNA_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: CREATE_QNA_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const getQnADetails = (pk) => async (dispatch) => {
    try {
        dispatch({ type: QNA_DETAILS_LOADING });

        const { data } = await axiosInstance.get(`/job/qna-details/${pk}/`);
        dispatch({
            type: QNA_DETAILS_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: QNA_DETAILS_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
};


export const getQnAList = () => async (dispatch) => {
    try {
        dispatch({ type: QNA_LIST_LOADING });

        const cachedData = localStorage.getItem('qnaList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: QNA_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('qnaList');
            }
        }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/job/interview-qna/${userDetail.id}/`);
        
        localStorage.setItem('qnaList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: QNA_LIST_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: QNA_LIST_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const deleteQnA = (pk) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/job/delete-interview-qna/${pk}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createLiveQna = (qnaData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_LIVEQNA_LOADING });
        const { data } = await axiosInstance.post('/job/create-live-qna/', qnaData);

        dispatch({
            type: CREATE_LIVEQNA_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: CREATE_LIVEQNA_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const getLiveQnaDetails = (pk) => async (dispatch) => {
    try {
        dispatch({ type: LIVEQNA_DETAILS_LOADING });

        const { data } = await axiosInstance.get(`/job/live-interview-details/${pk}/`);
        dispatch({
            type: LIVEQNA_DETAILS_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: LIVEQNA_DETAILS_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
};

export const getLiveQnaList = () => async (dispatch) => {
    try {
        dispatch({ type: LIVEQNA_LIST_LOADING });

        const cachedData = localStorage.getItem('liveQnaList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: LIVEQNA_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('liveQnaList');
            }
        }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/job/live-interview-qna/${userDetail.id}/`);
        localStorage.setItem('liveQnaList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: LIVEQNA_LIST_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: LIVEQNA_LIST_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const deleteLiveQna = (pk) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/job/delete-live-interview/${pk}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};  

export const submitAnswers = (pk, answers) => async (dispatch) => {
    try {
        const { data } = await axiosInstance.post(`/job/submit-answers/`, answers);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getResult = (pk) => async (dispatch) => {
    try {
        dispatch({ type: GET_RESULT_LOADING });

        const { data } = await axiosInstance.get(`/job/get-result/${pk}/`);

        dispatch({
            type: GET_RESULT_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: GET_RESULT_FAIL,
            payload: error.response.data.detail || 'Something went wrong',
        });
        toast.error(error.response.data.detail || 'Something went wrong');
    }
}

export const downloadResult = (pk) => async (dispatch) => {
    try {
        const response = await axiosInstance.get(`/job/resume/pdf/${pk}/`, { responseType: 'blob' });
        return response.data;
    } catch (error) {
        throw error;
    }
}