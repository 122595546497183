import React from 'react';
import Question from './Question';

const QuestionList = ({ questions, toggleAnswer }) => (
    <div className="space-y-4">
        {questions.map((item, index) => (
            <Question key={index} item={item} index={index} toggleAnswer={toggleAnswer} />
        ))}
    </div>
);

export default QuestionList;
