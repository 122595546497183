import React from 'react';
import '../../../../style/Style.css';

const Avatar = ({ isSpeaking }) => {
    return (
        <div className="avatar-container">
            <div className={`avatar ${isSpeaking ? 'speaking' : ''}`}>
                <img 
                    src="/images/interview-avatar.png" 
                    alt="Avatar" 
                />
                {isSpeaking && (
                    <div className="speech-bubble">
                        <div className="bubble-text">Speaking...</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Avatar;
