import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Userlogin, googleAuth, resendVerifyEmail } from '../../../redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useAuth } from '../../../Common/AuthProvider';
import "../../../style/Style.css"

const SigninPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const { loading, error } = authState;
    const { login, isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1);
        }
    }, [isAuthenticated]);

    const validate = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        } else if (email.length > 200) {
            errors.email = 'Email must be less than 200 characters.';
        }

        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length > 200) {
            errors.password = 'Password must be less than 200 characters.';
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            const userData = {
                email,
                password,
            };
            try {
                const res = await dispatch(Userlogin(userData));
                if (res && res.access) {
                    if (!res.user.is_verified) {
                        navigate('/email-verification');
                    } else {
                        login(res);
                    }
                }
            } catch (error) {
                console.error("Login error:", error);
            }
        }
    };

    const handleResendVerification = () => {
        dispatch(resendVerifyEmail(email)).then((res) => {
            if (res) {
                navigate('/email-verification', { state: { email: email } });
            }
        });
    };

    const handleGoogleSuccess = async (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const userData = {
            email: decoded.email,
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            id_token: token,
        };
        await dispatch(googleAuth(userData)).then((res) => {
            if (res && res.access) {
                login(res);
            }
        });
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const renderError = (err) => (
        <div>
          {err}
          {err.includes('Email is not verified') && (
            <button onClick={handleResendVerification} className="ml-2 underline">
              Resend Verification Email
            </button>
          )}
        </div>
    );

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Sign in to your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Or <Link to="/signup" className="font-medium text-primary-600 hover:text-primary-500">create a new account</Link>
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white mx-4 md:mx-0 py-8 px-4 shadow rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.email ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                                placeholder="Email"
                                maxLength="200"
                            />
                            {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                        </div>

                        <div>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.password ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                                placeholder="Password"
                                maxLength="200"
                            />
                            {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                        </div>

                        <div className="md:flex items-center justify-between">
                            <div className="flex mb-2 md:mb-0 items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm">
                                <Link to="/reset-password" className="font-medium text-primary-600 hover:text-primary-500">
                                    Forgot your password?
                                </Link>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-4 px-4 border border-transparent font-medium rounded-lg text-white bg-gradient-to-r from-primary-600 to-primary-500 hover:from-primary-700 hover:to-primary-600 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                                <svg
                                    className="w-6 h-6 -ml-2"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                                    <circle cx="8.5" cy="7" r="4" />
                                </svg>
                                <span className="ml-3">Sign In</span>
                            </button>
                        </div>
                    </form>
                    <div className="text-center mt-6">
                        <p className="font-bold text-gray-500">OR</p>
                    </div>
                    <div className='mx-auto justify-center text-center mx-4 md:mx-20 mt-4'>
                        <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                onFailure={handleGoogleFailure}
                            />
                        </GoogleOAuthProvider>
                    </div>
                    {loading && <p className="mt-4 text-gray-600">Loading...</p>}
                    {error && (
                        <div className="text-red-500 mt-2 text-sm">
                        {Array.isArray(error) ? error.map((err, index) => (
                            <div key={index}>
                            {renderError(err)}
                            </div>
                        )) : renderError(error)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SigninPage;
