import { 
    QNA_DETAILS_LOADING,
    QNA_DETAILS_SUCCESS,
    QNA_DETAILS_FAIL,

    LIVEQNA_DETAILS_LOADING,
    LIVEQNA_DETAILS_SUCCESS,
    LIVEQNA_DETAILS_FAIL,

    GET_RESULT_LOADING,
    GET_RESULT_SUCCESS,
    GET_RESULT_FAIL,
} from '../constants/qnaConstants' 

export const getQnaDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case QNA_DETAILS_LOADING:
            return { loading: true };

        case QNA_DETAILS_SUCCESS:
            return { loading: false, qnaDetails: action.payload };

        case QNA_DETAILS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const getLiveQnaDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case LIVEQNA_DETAILS_LOADING:
            return { loading: true };

        case LIVEQNA_DETAILS_SUCCESS:
            return { loading: false, liveQnaDetails: action.payload };

        case LIVEQNA_DETAILS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const getResultReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_RESULT_LOADING:
            return { loading: true };

        case GET_RESULT_SUCCESS:
            return { loading: false, results: action.payload };

        case GET_RESULT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};