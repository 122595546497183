import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { IoArrowBackCircleSharp } from 'react-icons/io5';

const LevelNavigation = ({ currentLevel, totalLevels, isLoading, nextLevel, prevLevel, handleBackButtonClick, setCurrentLevel }) => (
    <div className="flex justify-between items-center mb-4 w-full">
        {currentLevel > 1 && (
            <button
                onClick={prevLevel}
                className="fl   ex items-center px-2 md:px-4 py-1 md:py-2 bg-gradient-to-r from-gray-500 to-gray-600 text-white font-semibold rounded-full shadow-lg hover:from-gray-600 hover:to-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all duration-300"
            >
                <FaArrowLeft className="hidden md:block w-5 h-5 mr-2" /> Previous Level
            </button>
        )}
        {currentLevel === 1 && (
            <button
                onClick={handleBackButtonClick}
                className="flex items-center px-2 md:px-4 py-1 md:py-2 bg-gradient-to-r from-gray-500 to-gray-600 text-white font-semibold rounded-full shadow-lg hover:from-gray-600 hover:to-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all duration-300"
            >
                <IoArrowBackCircleSharp className="w-5 h-5 mr-2" /> Back
            </button>
        )}
        <h2 className="text-xl md:text-2xl font-bold text-gray-700 text-center">
            Level {currentLevel}
        </h2>
        {currentLevel < totalLevels && (
            <button
                onClick={() => setCurrentLevel(currentLevel + 1)}
                className="flex items-center px-2 md:px-4 py-1 md:py-2 bg-gradient-to-r from-primary-500 to-primary-600 text-white font-semibold rounded-full shadow-lg hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
            >
                Next Level <FaArrowRight className="hidden md:block w-5 h-5 ml-2" />
            </button>
        )}
        {currentLevel < 2 && currentLevel === totalLevels && (
            <button
                onClick={nextLevel}
                className="flex items-center px-2 md:px-4 py-1 md:py-2 bg-gradient-to-r from-primary-500 to-primary-600 text-white font-semibold rounded-full shadow-lg hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                disabled={isLoading}
            >
                {isLoading ? 'Loading...' : <>Create Next Level Questions <FaPlusCircle className="hidden md:block w-5 h-5 ml-2" /></>}
            </button>
        )}
        {currentLevel === 2 && <p></p>}
    </div>
);

export default LevelNavigation;
