import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { createJob, updateJob, fetchJobDetails } from '../../../redux/actions/jobActions';
import CommonEditor from '../../Components/Editor';
import FileSelector from '../../../Components/FileSelector';
import Button from '../../../Components/Button';

const AddJob = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [resume, setResume] = useState(null);
    const [resumePreview, setResumePreview] = useState(null);
    const [resumeError, setResumeError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const jobId = location.state?.id;

    useEffect(() => {
        if (jobId) {
            setIsEditing(true);
            dispatch(fetchJobDetails(jobId)).then((job) => {
                setTitle(job.title);
                setDescription(job.description);
                if (job.resume) {
                    setResumePreview({
                        name: job.resume.split('/').pop(),
                        url: job.resume,
                        type: 'application/pdf',
                    });
                }
            }).catch((error) => {
                setTitleError('Failed to load job details');
            });
        }
    }, [jobId, dispatch]);

    const handleResumeChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validTypes = ['application/pdf'];
            const maxSize = 2 * 1024 * 1024; // 2MB

            if (!validTypes.includes(file.type)) {
                setResumeError('Invalid file type. Only PDF files are allowed.');
                setResume(null);
                setResumePreview(null);
                e.target.value = null;
                return;
            }

            if (file.size > maxSize) {
                setResumeError('File size must be less than 2MB.');
                setResume(null);
                setResumePreview(null);
                e.target.value = null;
                return;
            }

            setResume(file);
            setResumePreview({
                name: file.name,
                type: file.type,
                url: URL.createObjectURL(file),
            });
            setResumeError('');
        } else {
            setResume(null);
            setResumePreview(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate title and description
        let isValid = true;

        if (!title) {
            setTitleError('Job title is required.');
            isValid = false;
        } else if (title.length > 100) {
            setTitleError('Job title must be less than 100 characters.');
            isValid = false;
        } else {
            setTitleError('');
        }

        if (!description) {
            setDescriptionError('Job description is required.');
            isValid = false;
        } else if (description.length > 3000) {
            setDescriptionError('Job description must be less than 3000 characters.');
            isValid = false;
        } else {
            setDescriptionError('');
        }

        if (!resume && !isEditing) {
            setResumeError('Resume file is required.');
            isValid = false;
        }

        if (!isValid) {
            return;
        }

        setIsSubmitting(true);
        const userId = JSON.parse(localStorage.getItem('user')).id;
        const jobData = {
            title,
            description,
            user: userId,
        };

        if (resume) {
            jobData.resume = resume;
        }

        try {
            let res;
            if (isEditing) {
                res = await dispatch(updateJob(jobId, jobData));
            } else {
                res = await dispatch(createJob(jobData));
            }

            setTitle('');
            setDescription('');
            setResume(null);
            setResumePreview(null);

            if (res) {
                localStorage.removeItem('jobList');
                navigate('/job-list');
            } else {
                setTitleError(`Failed to ${isEditing ? 'update' : 'add'} job`);
            }
        } catch (error) {
            setTitleError('An error occurred');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 py-8">
            <main className="w-full mx-4 md:mx-0 max-w-4xl p-8 bg-white rounded-lg shadow-lg">
                <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-800 mb-6">{isEditing ? 'Edit Job' : 'Add Job'}</h1>
                <form onSubmit={handleSubmit} className="space-y-6" encType="multipart/form-data">
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="title">Job Title</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 ${titleError ? 'border-red-500' : 'border-gray-300'}`}
                            maxLength="100"
                            required
                        />
                        {titleError && <p className="text-red-500 text-xs mt-2">{titleError}</p>}
                    </div>
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2" htmlFor="description">Job Description</label>
                        <CommonEditor
                            value={description}
                            onChange={(value) => setDescription(value)}
                            className={`h-96 ${descriptionError ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {descriptionError && <p className="text-red-500 text-xs mt-2">{descriptionError}</p>}
                    </div>
                    <div>
                        <FileSelector
                            id="resume"
                            onChange={handleResumeChange}
                            error={resumeError}
                            preview={resumePreview}
                            accept=".pdf"
                            placeholder="Upload PDF file only"
                        />
                    </div>
                    <div className="flex justify-center">
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : isEditing ? 'Update Job' : 'Add Job'}
                        </Button>
                    </div> 
                </form>
            </main>
        </div>
    );
};

export default AddJob;
