import React from 'react';
import QuestionAnswer from './QuestionAnswer';

const QuestionsAnswers = ({ qnaData, evaluations }) => (
    <div>
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Questions and Answers</h2>
        <div className="space-y-4">
            {Object.entries(qnaData).map(([question, answer], index) => (
                <QuestionAnswer
                    key={index}
                    question={question}
                    answer={answer}
                    evaluation={evaluations[index].evaluation}
                    index={index}
                />
            ))}
        </div>
    </div>
);

export default QuestionsAnswers;
