import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveQnaDetails, submitAnswers } from '../../../../redux/actions/qnaActions';
import Modal from '../../../Components/Modal';
import QuestionDisplay from './QuestionDisplay';
import AnswerInput from './AnswerInput';
import NavigationButtons from './NavigationButtons';
import Avatar from './Avatar';
import Loading from '../../../Components/Loading';

const AudioInterview = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state;
    const [qnaList, setQnaList] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isEvaluating, setIsEvaluating] = useState(false);
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    const recognitionRef = useRef(null);

    const liveQnaDetail = useSelector((state) => state.liveQnaDetails.liveQnaDetails);

    useEffect(() => {
        dispatch(getLiveQnaDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (liveQnaDetail && liveQnaDetail.questions) {
            const parsedQna = JSON.parse(liveQnaDetail.questions);
            if (Array.isArray(parsedQna.interviewQuestions)) {
                setQnaList(parsedQna.interviewQuestions);
            }
        }
    }, [liveQnaDetail]);

    useEffect(() => {
        if (qnaList.length > 0) {
            handleSpeak(qnaList[currentQuestionIndex].question);
        }
    }, [qnaList, currentQuestionIndex]);

    const handleInputChange = (value, append = false) => {
        setUserAnswers(prevState => ({
            ...prevState,
            [currentQuestionIndex]: append
                ? (prevState[currentQuestionIndex] || '') + ' ' + value
                : value
        }));
    };

    const handleNextQuestion = () => {
        handleStopListening();
        if (currentQuestionIndex < qnaList.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsModalOpen(true);
        }
    };

    const handlePreviousQuestion = () => {
        handleStopListening();
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleSubmitAnswers = async () => {
        setIsModalOpen(false);
        setIsEvaluating(true);

        try {
            const defaultAnswer = "No answer provided.";
            const updatedUserAnswers = {};

            qnaList.forEach((questionObj, index) => {
                const question = questionObj.question;
                updatedUserAnswers[question] = userAnswers[index] || defaultAnswer;
            });

            const userans = JSON.stringify(updatedUserAnswers);
            const ansdetail = {
                id: id,
                job: liveQnaDetail.job,
                interview_type: liveQnaDetail.interview_type,
                user_qna: userans
            };

            const fakeProgress = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        clearInterval(fakeProgress);
                        return 100;
                    }
                    return prevProgress + 1;
                });
            }, 100);

            const response = await dispatch(submitAnswers(id, ansdetail));
            if (response) {
                localStorage.removeItem('liveQnaList');
                navigate('/live-interview-result', { state: { id: id } });
            }
        } catch (error) {
            console.error('Error submitting answers:', error);
        } finally {
            setIsEvaluating(false);
        }
    };

    const answeredQuestionsCount = Object.values(userAnswers).filter(answer => answer !== "No answer provided.").length;
    const totalQuestionsCount = qnaList.length;

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = SpeechRecognition ? new SpeechRecognition() : null;

    useEffect(() => {
        if (recognition) {
            recognitionRef.current = recognition;
            recognitionRef.current.continuous = false;
            recognitionRef.current.interimResults = false;

            recognitionRef.current.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                handleInputChange(transcript, true);
            };

            recognitionRef.current.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
                setIsListening(false);
            };

            recognitionRef.current.onend = () => {
                setIsListening(false);
            };
        }
    }, [recognition]);

    const handleStartListening = () => {
        if (recognitionRef.current && !isListening) {
            recognitionRef.current.start();
            setIsListening(true);
        }
    };

    const handleStopListening = () => {
        if (recognitionRef.current && isListening) {
            recognitionRef.current.stop();
            setIsListening(false);
        }
    };

    const handleSpeak = (text) => {
        const speech = new SpeechSynthesisUtterance(text);
        speech.onstart = () => {
            setIsSpeaking(true);
        };
        speech.onend = () => {
            setIsSpeaking(false);
            handleStartListening();
        };
        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(speech);
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-50 py-8">
            <main className="w-full max-w-3xl mx-4 md:mx-0 p-6 bg-white rounded-lg shadow-lg">
                <div className="text-center mb-6">
                    <h1 className="text-2xl md:text-4xl font-bold text-gray-800">Audio-Based Interview</h1>
                </div>
                <div className="md:p-6">
                    {isEvaluating ? (
                        <Loading progress={progress} message="We are evaluating your answers..." />
                    ) : (
                        <>
                            <Avatar isSpeaking={isSpeaking} />
                            {qnaList.length > 0 && (
                                <div className="space-y-6">
                                    <QuestionDisplay
                                        question={qnaList[currentQuestionIndex].question}
                                        handleSpeak={() => handleSpeak(qnaList[currentQuestionIndex].question)}
                                        questionNumber={currentQuestionIndex + 1}
                                    />
                                    <AnswerInput
                                        answer={userAnswers[currentQuestionIndex] || ''}
                                        isListening={isListening}
                                        handleInputChange={handleInputChange}
                                        handleStartListening={handleStartListening}
                                        handleStopListening={handleStopListening}
                                        handleSpeakAnswer={() => handleSpeak(userAnswers[currentQuestionIndex] || '')}
                                    />
                                    <NavigationButtons
                                        currentQuestionIndex={currentQuestionIndex}
                                        totalQuestions={qnaList.length}
                                        handlePreviousQuestion={handlePreviousQuestion}
                                        handleNextQuestion={handleNextQuestion}
                                        handleSubmitAnswers={() => setIsModalOpen(true)}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </main>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Confirm Submission</h2>
                <p className="text-gray-700 mb-4">You have answered {answeredQuestionsCount} out of {totalQuestionsCount} questions.</p>
                <div className="flex justify-between">
                    <button
                        onClick={() => setIsModalOpen(false)}
                        className="px-4 py-2 bg-gray-500 text-white font-semibold rounded-full shadow-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all duration-300"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmitAnswers}
                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-300"
                    >
                        Confirm
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default AudioInterview;
