import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { createQnA, getInterviewType } from '../../../redux/actions/qnaActions';
import Button from '../../../Components/Button';
import Loading from '../../Components/Loading';

const CreateQna = () => {
    const dispatch = useDispatch();
    const [jobType, setJobType] = useState([]);
    const [interviewType, setInterviewType] = useState([]);
    const [initData, setInitData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getInterviewType()).then((res) => {
            if (res) {
                setInterviewType(res.interview_types);
                setJobType(res.jobs);
            }
        });
    }, [dispatch]);

    const handleInputChange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        setInitData({ ...initData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return; // Prevent multiple submissions
        setIsLoading(true);
        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const formData = {
            user: userDetail.id,
            id: null,
            job: initData.selectJob,
            level: 1,
            interview_type: initData.selectInterviewType,
        };
        setInitData({});

        try {
            const fakeProgress = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(fakeProgress);
                        return 100;
                    }
                    return prevProgress + 1; // Adjust increment as needed
                });
            }, 200); // Adjust interval as needed

            const res = await dispatch(createQnA(formData));
            if (res) {
                localStorage.removeItem('qnaList');
                navigate('/que-ans', { state: { id: res.id } });
            }
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="justify-center items-center">
            <main className="p-4">
                <div className="grid grid-col-1 mt-6 mb-6">
                    <h1 className="text-center text-2xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Create QNA for Interview</h1>
                    <p className="text-center text-gray-500 dark:text-gray-300">
                        Add new job details!
                    </p>
                </div>
                <div className="md:min-h-screen md:p-6 flex flex-col items-center">
                    {isLoading ? (
                        <Loading progress={progress} message="Creating Interview QNA..." />
                    ) : (
                        <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-8">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="grid gap-5 grid-cols-1 md:grid-cols-10">
                                    <div className="my-4 md:col-span-5">
                                        <select
                                            id="selectJob"
                                            value={initData.selectJob || ''}
                                            onChange={handleInputChange}
                                            className={`w-full px-4 py-2 text-gray-500 font-normal border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400 ${errors.selectJob ? 'border-red-500' : 'border-gray-300'}`}
                                            required
                                        >
                                            <option value="">Select Job</option>
                                            {jobType && jobType.map((type) => (
                                                <option key={type.title} value={type.id}>
                                                    {type.title}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.selectJob && <p className="text-red-500 text-xs mt-2">{errors.selectJob}</p>}
                                    </div>
                                    <div className="my-4 md:col-span-5">
                                        <select
                                            id="selectInterviewType"
                                            value={initData.selectInterviewType || ''}
                                            onChange={handleInputChange}
                                            className={`w-full px-4 py-2 text-gray-500 font-normal border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400 ${errors.selectInterviewType ? 'border-red-500' : 'border-gray-300'}`}
                                            required
                                        >
                                            <option value="">Select Interview Type</option>
                                            {interviewType && interviewType.map((type) => (
                                                <option key={type.id} value={type.id}>
                                                    {type.name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.selectInterviewType && <p className="text-red-500 text-xs mt-2">{errors.selectInterviewType}</p>}
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Button type="submit" disabled={isLoading}>
                                        {isLoading ? 'Submitting...' : 'Submit'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                    <ToastContainer />
                </div>
            </main>
        </div>
    );
};

export default CreateQna;
