import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Frontend/page/Home';
import About from '../Frontend/page/About';
import Services from '../Frontend/page/Service';
import Contact from '../Frontend/page/Contact';
import SigninPage from '../Frontend/page/Auth/Signin'; 
import ForgetPassword from '../Frontend/page/Auth/ForgetPassword'; 
import EmailVerification from '../Frontend/page/Auth/EmailVerification'; 
import PrivacyPolicy from '../Frontend/page/PrivacyPolicy';
import TermsAndConditions from '../Frontend/page/TermsAndConditions ';
import SignupPage from '../Frontend/page/Auth/Signup';
import CreateNewPassword from '../Frontend/page/Auth/CreateNewPassword';
import DashboardHome from '../Dashboard/DashboardHome';
import FrontendLayout from '../Layouts/FrontendLayout';
import DashboardLayout from '../Layouts/DashboardLayout';
import Team from '../Dashboard/Page/Team';
import AddTeamMember from '../Dashboard/Page/Team/AddTeamMember';
import Profile from '../Dashboard/Page/Profile';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import EmailVerified from '../Frontend/page/Auth/EmailVerified';
import CreateQna from '../Dashboard/Page/InterviewQue/CreateQna';
import QueAns from '../Dashboard/Page/InterviewQue/QueAns';
import LiveQnaList from '../Dashboard/Page/LiveQna/LiveQnaList';
import CreateLiveQnaInterview from '../Dashboard/Page/LiveQna/CreateLiveQna';
import LiveQnaResult from '../Dashboard/Page/LiveQna/LiveQnaResult';
import AudioInterview from '../Dashboard/Page/LiveQna/InterviewType/AudioInterview';
import TextInterview from '../Dashboard/Page/LiveQna/InterviewType/TextInterview';
import Resumes from '../Dashboard/Page/Resume/Resumes';
import CreateResume from '../Dashboard/Page/Resume/CreateResume';
import ResumePreview from '../Dashboard/Page/Resume/CreatedResumePreview';
import QnaList from '../Dashboard/Page/InterviewQue/QnaList';
import JobList from '../Dashboard/Page/Jobs/JobList';
import AddJob from '../Dashboard/Page/Jobs/AddJob';
import ResumePreview2 from '../Dashboard/Page/Resume/CreateResumePreview2';

const RouteList = () => {
  return (
    <Routes>
      <Route element={<FrontendLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/service" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/term-and-condition" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Route>
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<ProtectedRoute element={<DashboardHome />} />} />
        <Route path="/job-list" element={<ProtectedRoute element={<JobList />} />} />
        <Route path="/add-job" element={<ProtectedRoute element={<AddJob />} />} />
        <Route path="/edit-job" element={<ProtectedRoute element={<AddJob />} />} />
        <Route path="/interview-qna" element={<ProtectedRoute element={<QnaList />} />} />
        <Route path="/create-qna" element={<ProtectedRoute element={<CreateQna />} />} />
        <Route path="/que-ans" element={<ProtectedRoute element={<QueAns />} />} />
        <Route path="/live-qna" element={<ProtectedRoute element={<LiveQnaList />} />} />
        <Route path="/create-live-qna" element={<ProtectedRoute element={<CreateLiveQnaInterview />} />} />
        <Route path="/audio-interview" element={<ProtectedRoute element={<AudioInterview />} />} />
        <Route path="/text-interview" element={<ProtectedRoute element={<TextInterview />} />} />
        <Route path="/live-interview-result" element={<ProtectedRoute element={<LiveQnaResult />} />} />
        <Route path="/resumes" element={<ProtectedRoute element={<Resumes />} />} />
        <Route path="/create-resume" element={<ProtectedRoute element={<CreateResume />} />} />
        <Route path="/preview-resume-1" element={<ProtectedRoute element={<ResumePreview />} />} />
        <Route path="/preview-resume-2" element={<ProtectedRoute element={<ResumePreview2 />} />} />
        <Route path="/team" element={<ProtectedRoute element={<Team />} />} />
        <Route path="/add-member" element={<ProtectedRoute element={<AddTeamMember />} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
      </Route>
    </Routes>
  );
};

export default RouteList;
