import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveQnaDetails, submitAnswers } from '../../../../redux/actions/qnaActions';
import Modal from '../../../Components/Modal';

const TextInterview = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state;
    const [qnaList, setQnaList] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const liveQnaDetail = useSelector((state) => state.liveQnaDetails.liveQnaDetails);

    useEffect(() => {
        dispatch(getLiveQnaDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (liveQnaDetail && liveQnaDetail.questions) {
            const parsedQna = JSON.parse(liveQnaDetail.questions);
            if (Array.isArray(parsedQna.interviewQuestions)) {
                setQnaList(parsedQna.interviewQuestions);
            }
        }
    }, [liveQnaDetail]);

    const handleInputChange = (value) => {
        setUserAnswers(prevState => ({
            ...prevState,
            [currentQuestionIndex]: value
        }));
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex < qnaList.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsModalOpen(true); // Show the confirmation modal on the last question
        }
    };

    const handleSkipQuestion = () => {
        handleInputChange("No answer provided.");
        handleNextQuestion();
    };

    const handleSubmitAnswers = async () => {
        setIsModalOpen(false); // Close the modal

        try {
            const defaultAnswer = "No answer provided.";
            const updatedUserAnswers = {};

            qnaList.forEach((questionObj, index) => {
                const question = questionObj.question;
                updatedUserAnswers[question] = userAnswers[index] || defaultAnswer;
            });

            const userans = JSON.stringify(updatedUserAnswers);
            const ansdetail = {
                id: id,
                job: liveQnaDetail.job,
                interview_type: liveQnaDetail.interview_type,
                user_qna: userans
            };

            const response = await dispatch(submitAnswers(id, ansdetail));
            if (response) {
                navigate('/live-interview-result', { state: { id: id } });
            }
        } catch (error) {
            console.error('Error submitting answers:', error);
        }
    };

    const answeredQuestionsCount = Object.values(userAnswers).filter(answer => answer !== "No answer provided.").length;
    const totalQuestionsCount = qnaList.length;

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 py-8">
            <main className="w-full max-w-3xl p-4 bg-white rounded-lg shadow-lg">
                <div className="text-center mb-6">
                    <h1 className="text-4xl font-bold text-gray-800">Text-Based Interview</h1>
                </div>
                <div className="p-6">
                    {qnaList.length > 0 && (
                        <div className="space-y-6">
                            <div>
                                <h3 className="text-lg font-semibold text-gray-800">Question {currentQuestionIndex + 1}:</h3>
                                <p className="text-gray-700">{qnaList[currentQuestionIndex].question}</p>
                            </div>
                            <div>
                                <textarea
                                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
                                    rows="4"
                                    value={userAnswers[currentQuestionIndex] || ''}
                                    onChange={(e) => handleInputChange(e.target.value)}
                                    placeholder="Type your answer here..."
                                />
                            </div>
                            <div className="flex justify-between">
                                <button
                                    onClick={handleSkipQuestion}
                                    className="px-4 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all duration-300"
                                >
                                    Skip Question
                                </button>
                                {currentQuestionIndex < qnaList.length - 1 ? (
                                    <button
                                        onClick={handleNextQuestion}
                                        className="px-4 py-2 bg-primary-500 text-white font-semibold rounded-lg shadow-lg hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-all duration-300"
                                    >
                                        Next Question
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => setIsModalOpen(true)}
                                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-300"
                                    >
                                        Submit Answers
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </main>

            {/* Confirmation Modal */}
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Confirm Submission</h2>
                <p className="text-gray-700 mb-4">You have answered {answeredQuestionsCount} out of {totalQuestionsCount} questions.</p>
                <div className="flex justify-between">
                    <button
                        onClick={() => setIsModalOpen(false)}
                        className="px-4 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all duration-300"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmitAnswers}
                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-300"
                    >
                        Confirm
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default TextInterview;
