import React from 'react';
import ContactUs from './Contact/ContactUs';
import ContactHero from './Contact/ContactHero';
import ContactMap from './Contact/ContactMap';
import CallToAction from './Home/Calltoaction';

const Contact = () => {
  return (
    <>
      <ContactHero />
      <ContactUs />
      <CallToAction />
    </>
  );
};

export default Contact;
