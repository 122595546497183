  import React from 'react';

  const Features = () => {
    return (
      <div className="container px-4 py-12 md:py-24 sm:max-w-xl md:max-w-full md:px-24">
        
        <div className="grid md:gap-10 lg:grid-cols-2 md:mt-12">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="max-w-xl mb-6">
              <p className="max-w-max text-center py-1 px-4 rounded-full mb-1 font-sans font-semibold tracking-tight text-primary-600 bg-primary-50 sm:leading-none">
                CUSTOMIZATION
              </p>
              <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Create Custom Q&A
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Generate interview questions and practice answers based on your specific job description and resume. Our tool offers multiple levels of difficulty to match your expertise and experience.
              </p>
              <ul className="custom-list md:text-lg mt-4 text-gray-700">
                <li>Level-based question sets</li>
                <li>Real-time feedback</li>
                <li>Extensive question bank</li>
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-center lg:pl-8">
            <div className="relative p-3 bg-white rounded-lg shadow-lg">
              <img className="rounded-lg" src="./images/created-qna.png" alt="Q&A Creation Tool" />
              <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-yellow-500 opacity-25 rounded-lg"></div>
            </div>
          </div>
        </div>

        <div className="grid md:gap-10 lg:grid-cols-2 mt-20 md:mt-32">
          <div className="hidden md:contents flex items-center justify-center lg:pl-8">
            <div className="relative p-3 bg-white rounded-lg shadow-lg">
              <img className="rounded-lg" src="./images/created-resume.png" alt="Resume Creation Tool" />
              <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-yellow-500 opacity-25 rounded-lg"></div>
            </div>
          </div>
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:pl-8 lg:max-w-lg">
            <div className="max-w-xl mb-6">
              <p className="max-w-max text-center py-1 px-4 rounded-full mb-1 font-sans font-semibold tracking-tight text-primary-600 bg-primary-50 sm:leading-none">
                RESUME
              </p>
              <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Build Your Resume
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Create a professional resume based on your job description and previous experiences. Our platform provides personalized suggestions to help you highlight your strengths and achievements.
              </p>
              <ul className="custom-list md:text-lg mt-4 text-gray-700">
                <li>Customized resume templates</li>
                <li>Keyword optimization</li>
                <li>Expert tips and advice</li>
              </ul>
            </div>
          </div>
          <div className="contents md:hidden flex items-center justify-center lg:pl-8">
            <div className="relative p-3 bg-white rounded-lg shadow-lg">
              <img className="rounded-lg" src="./images/created-resume.png" alt="Resume Creation Tool" />
              <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-yellow-500 opacity-25 rounded-lg"></div>
            </div>
          </div>
        </div>

        <div className="grid md:gap-10 lg:grid-cols-2 mt-20 md:mt-32">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="max-w-xl mb-6">
              <p className="max-w-max text-center py-1 px-4 rounded-full mb-1 font-sans font-semibold tracking-tight text-primary-600 bg-primary-50 sm:leading-none">
                INSIGHTS
              </p>
              <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Visualize Your Results
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Get detailed analytics of your interview performance. Our tool evaluates your answers, providing insights into your strengths and areas for improvement.
              </p>
              <ul className="custom-list md:text-lg mt-4 text-gray-700">
                <li>Comprehensive performance charts</li>
                <li>Detailed feedback</li>
                <li>Progress tracking</li>
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-center lg:pl-8">
            <div className="relative p-3 bg-white rounded-lg shadow-lg">
              <img className="rounded-lg" src="./images/interview-result.png" alt="Interview Results Visualization" />
              <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-yellow-500 opacity-25 rounded-lg"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default Features;
