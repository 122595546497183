import React from 'react';

const AboutUsHero = () => {
  return (
    <div className="relative bg-primary-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto">
        <div className="relative z-10 bg-primary-50 lg:w-full lg:max-w-2xl">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-28 text-primary-50 transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <div className="py-10 md:pt-16 md:pb-20 px-4 sm:px-6 lg:px-8 lg:py-28">
            <div className="relative">
              <p className="max-w-max py-1 px-4 rounded-full mb-1 font-sans text-md font-semibold tracking-tight text-primary-600 bg-primary-100 sm:leading-none">
                ABOUT INTERVIEW AI
              </p>
              <h1 className="mt-3 hero-heading text-4xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-tight">
              Empowering Job Seekers with AI Interviews
              </h1> 
            </div>
          </div>
        </div>
      </div>
      <div className="relative lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="/images/about-hero.jpg"
          alt="Team working"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-primary-500 to-primary-700 opacity-40"></div>
      </div>
    </div>
  );
};

export default AboutUsHero;
