import React, { useState } from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaUsers } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { contactMessage } from '../../../redux/actions/authActions';

const ContactUs = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      await dispatch(contactMessage(data));
      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error('Failed to submit your message. Please try again.');
    }
  };

  const handleCloseModal = () => {
    setIsSubmitted(false);
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 py-24">
      <div className="container px-4 md:px-8 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {/* Contact Information Section */}
          <div className="space-y-8">
            <div className="mb-6">
              <h3 className="inline-block px-3 py-1 mb-4 text-sm tracking-wider text-primary-600 font-bold uppercase rounded-full bg-primary-50">
                Get in Touch
              </h3>
              <h1 className="text-3xl font-semibold text-gray-800 capitalize dark:text-white">
                How can we assist you?
              </h1>
              <p className="mt-6 text-gray-700 dark:text-gray-400">
                Have questions or need more information about our AI-powered interview preparation platform? We're here to help. Reach out to us for any inquiries or support.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <div className="flex items-start my-6">
                <FaMapMarkerAlt className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Office Location</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">16122 Collins Street, Melbourne, Australia</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaEnvelope className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Send a Message</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">info@Interview.AI.com</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">support@Interview.AI.com</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaPhoneAlt className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Call Us Directly</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">1-800-555-1234</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">1-800-555-5678</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaUsers className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Join Our Team</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">careers@Interview.AI.com</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">hr@Interview.AI.com</p>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form Section */}
          <div>
            <div className="max-w-lg px-8 py-10 mx-auto bg-white rounded-lg shadow-lg dark:bg-gray-900">
              <h1 className="text-3xl font-semibold text-gray-800 capitalize dark:text-white">
                How can we help you?
              </h1>
              <form className="mt-6 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Enter your name*</label>
                    <input
                      type="text"
                      {...register('name', {
                        required: 'Name is required',
                        maxLength: {
                          value: 200,
                          message: 'Name must be less than 200 characters',
                        },
                      })}
                      placeholder="What's your good name?"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email address*</label>
                    <input
                      type="email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: 'Invalid email address',
                        },
                        maxLength: {
                          value: 200,
                          message: 'Email must be less than 200 characters',
                        },
                      })}
                      placeholder="Enter your email address"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Phone number</label>
                    <input
                      type="text"
                      {...register('phone', {
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Invalid phone number',
                        },
                        maxLength: {
                          value: 15,
                          message: 'Phone number must be less than 15 characters',
                        },
                      })}
                      placeholder="Enter your phone number"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    {errors.phone && <span className="text-red-500 text-sm">{errors.phone.message}</span>}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Subject</label>
                    <input
                      type="text"
                      {...register('subject', {
                        maxLength: {
                          value: 200,
                          message: 'Subject must be less than 200 characters',
                        },
                      })}
                      placeholder="How can we help you?"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    {errors.subject && <span className="text-red-500 text-sm">{errors.subject.message}</span>}
                  </div>
                </div>
                <div className="mt-6">
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Your message</label>
                  <textarea
                    {...register('message', {
                      maxLength: {
                        value: 1000,
                        message: 'Message must be less than 1000 characters',
                      },
                    })}
                    className="block w-full h-24 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-24 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Describe your inquiry or issue"
                  ></textarea>
                  {errors.message && <span className="text-red-500 text-sm">{errors.message.message}</span>}
                </div>
                <button className="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform rounded-full bg-gradient-to-r from-primary-600 to-primary-500 text-white hover:from-primary-700 hover:to-primary-600 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600">
                  Get in touch
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isSubmitted} onClose={handleCloseModal}>
        <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
        <p className="text-gray-700 mb-4">Your message has been successfully sent. We'll get back to you soon!</p>
        <button
          onClick={handleCloseModal}
          className="px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
        >
          Close
        </button>
      </Modal>
    </section>
  );
};

export default ContactUs;
