import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];
const renderLabel = ({ name, value }) => `${name}: ${value}%`;

const PerformanceOverview = ({ pieData }) => (
    <div className="mb-6 md:mb-12">
        <h2 className="text-xl text-center md:text-start md:text-2xl font-semibold text-gray-700 mb-4">Performance Overview</h2>
        <div className="flex justify-center">
            <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                    <Pie
                        data={pieData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label={renderLabel}
                    >
                        {pieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </div>
    </div>
);

export default PerformanceOverview;
