import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getResult } from '../../../redux/actions/qnaActions';
import Header from './LiveQna/Header';
import PerformanceOverview from './LiveQna/PerformanceOverview';
import QuestionsAnswers from './LiveQna/QuestionsAnswers';
import { FaSpinner } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { IoMdInformationCircleOutline } from 'react-icons/io';

const LiveQnaResult = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = location.state;

    const getQnaResult = useSelector((state) => state.results.results);
    
    useEffect(() => {
        dispatch(getResult(id));
    }, [dispatch, id]);

    if (!getQnaResult) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100">
                <div className="text-center">
                    <FaSpinner className="animate-spin text-gray-600 w-8 h-8 mx-auto" />
                    <p className="text-gray-600 mt-4">Loading...</p>
                </div>
            </div>
        );
    }

    let qnaData, evaluations;

    try {
        qnaData = JSON.parse(getQnaResult.user_qna);
        evaluations = JSON.parse(getQnaResult.model_response).evaluations;
    } catch (error) {
        console.error('Error parsing JSON data:', error);
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100">
                <div className="text-center">
                    <MdErrorOutline className="text-red-600 w-16 h-16 mx-auto" />
                    <p className="text-red-600 mt-4 text-xl">Error parsing result data. Please try again later.</p>
                </div>
            </div>
        );
    }

    if (!qnaData || !evaluations) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100">
                <div className="text-center">
                    <IoMdInformationCircleOutline className="text-gray-600 w-24 h-24 mx-auto" />
                    <p className="text-gray-600 text-xl mt-4">No data available for this interview.</p>
                    <p className="text-gray-500 mt-2">Please check back later or refresh the page.</p>
                    <button
                        onClick={() => window.history.back()}
                        className="mt-4 px-4 py-2 bg-gray-600 text-white font-semibold rounded-lg shadow-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 transition-all duration-300"
                    >
                        Go Back
                    </button>
                </div>
            </div>
        );
    }

    const convertToPercentage = (value) => {
        return value <= 1 ? value * 100 : value;
    };

    const performanceData = evaluations.map((evaluation) => ({
        name: 'Performance',
        correctness: convertToPercentage(evaluation.evaluation.correctness),
        completeness: convertToPercentage(evaluation.evaluation.completeness),
        relevance: convertToPercentage(evaluation.evaluation.relevance),
    }));

    const pieData = [
        { name: 'Correctness', value: performanceData.reduce((acc, curr) => acc + curr.correctness, 0) / performanceData.length },
        { name: 'Completeness', value: performanceData.reduce((acc, curr) => acc + curr.completeness, 0) / performanceData.length },
        { name: 'Relevance', value: performanceData.reduce((acc, curr) => acc + curr.relevance, 0) / performanceData.length },
    ];

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 py-8">
            <main className="w-full max-w-5xl mx-2 md:mx-0 p-3 md:p-8 bg-white rounded-lg shadow-lg">
                <Header />
                <PerformanceOverview pieData={pieData} />
                <QuestionsAnswers qnaData={qnaData} evaluations={evaluations} />
            </main>
        </div>
    );
};

export default LiveQnaResult;
