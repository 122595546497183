import React from 'react';

const teamMembers = [
  {
    name: 'Alicia Stone',
    role: 'Lead AI Developer',
    image: 'https://randomuser.me/api/portraits/women/44.jpg',
    description: 'Alicia spearheads our AI initiatives, ensuring our interview simulations are both realistic and challenging.'
  },
  {
    name: 'Mark Johnson',
    role: 'Senior Project Manager',
    image: 'https://randomuser.me/api/portraits/men/46.jpg',
    description: 'Mark coordinates project workflows and ensures timely delivery of new features and updates.'
  },
  {
    name: 'Sophia Lee',
    role: 'Chief Data Scientist',
    image: 'https://randomuser.me/api/portraits/women/47.jpg',
    description: 'Sophia analyzes data patterns to continuously improve our AI algorithms and user experience.'
  },
  {
    name: 'James Anderson',
    role: 'Software Engineering Lead',
    image: 'https://randomuser.me/api/portraits/men/48.jpg',
    description: 'James oversees the development of our platform, ensuring it is scalable and robust.'
  },
  {
    name: 'Olivia Brown',
    role: 'Head of UX/UI Design',
    image: 'https://randomuser.me/api/portraits/women/49.jpg',
    description: 'Olivia creates intuitive and engaging interfaces to enhance user interaction with our platform.'
  },
  {
    name: 'Lucas Miller',
    role: 'Customer Success Director',
    image: 'https://randomuser.me/api/portraits/men/50.jpg',
    description: 'Lucas is dedicated to ensuring our users achieve their goals through excellent support and resources.'
  }
];

const TeamSection = () => (
  <section className="bg-gray-50 pt-12 md:pt-16 pb-24">
    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="max-w-xl mb-10 md:mx-auto text-center lg:max-w-2xl md:mb-24">
        <p className="inline-block px-3 py-1 text-sm tracking-wider text-primary-600 font-bold uppercase rounded-full bg-primary-50">
          Our Team
        </p>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Meet the Minds Driving Our Innovation
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Our dedicated team of professionals works tirelessly to bring you the best in AI-driven interview preparation. Get to know the experts who make it all possible.
        </p>
      </div>
      <div className="grid gap-10 row-gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {teamMembers.map((member, index) => (
          <div key={index} className="bg-white mt-2 pt-12 md:p-6 rounded-lg shadow-lg">
            <div className="flex justify-center -mt-16 md:justify-end">
              <img className="w-20 h-20 object-cover rounded-full border-2 border-primary-500" src={member.image} alt={member.name} />
            </div>
            <div className="text-center md:text-left">
              <h2 className="text-lg font-semibold text-gray-900 mt-2">{member.name}</h2>
              <p className="text-primary-500">{member.role}</p>
              <p className="mt-2 text-gray-600">{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default TeamSection;
