import axiosInstance, { axiosInstanceWithMultipart } from '../../Common/axiosInstance'; 

import {
    RESUME_TEMPLATES_LOADING,
    RESUME_TEMPLATES_SUCCESS,
    RESUME_TEMPLATES_FAIL,

    CREATE_RESUME_LOADING,
    CREATE_RESUME_SUCCESS,
    CREATE_RESUME_FAIL,

    RESUME_LIST_LOADING,
    RESUME_LIST_SUCCESS,
    RESUME_LIST_FAIL,

    RESUME_DETAILS_LOADING,
    RESUME_DETAILS_SUCCESS,
    RESUME_DETAILS_FAIL,

    UPDATE_RESUME_LOADING,
    UPDATE_RESUME_SUCCESS,
    UPDATE_RESUME_FAIL,
} from '../constants/resumeConstants';

export const getResumeTemplates = () => async (dispatch) => {
    try {
        dispatch({ type: RESUME_TEMPLATES_LOADING });

        // const cachedData = localStorage.getItem('resumeTemplates');
        // if (cachedData) {
        //     const parsedData = JSON.parse(cachedData);
        //     const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        //     const currentTime = new Date().getTime();

        //     if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
        //         dispatch({
        //             type: RESUME_TEMPLATES_SUCCESS,
        //             payload: parsedData.data,
        //         });
        //         return parsedData.data;
        //     } else {
        //         localStorage.removeItem('resumeTemplates');
        //     }
        // }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/job/resume-templates/${userDetail.id}/`);
        localStorage.setItem('resumeTemplates', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: RESUME_TEMPLATES_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: RESUME_TEMPLATES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const createResume = (formData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_RESUME_LOADING });
        const { data } = await axiosInstance.post('/job/create-resume/', formData);

        dispatch({
            type: CREATE_RESUME_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: CREATE_RESUME_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    }
};

export const fetchResumes = () => async (dispatch) => {
    try {
        dispatch({ type: RESUME_LIST_LOADING });

        const cachedData = localStorage.getItem('resumeList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: RESUME_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('resumeList');
            }
        }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/job/resumes/${userDetail.id}/`);
        localStorage.setItem('resumeList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: RESUME_LIST_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: RESUME_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const fetchResumeDetails = (resumeId) => async (dispatch) => {
    try {
        dispatch({ type: RESUME_DETAILS_LOADING });

        const { data } = await axiosInstance.get(`/job/get-resume/${resumeId}/`);

        dispatch({
            type: RESUME_DETAILS_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: RESUME_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const updateResume = (resumeId, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_RESUME_LOADING });
        const { data } = await axiosInstance.put(`/job/update-resume/${resumeId}/`, formData);

        dispatch({
            type: UPDATE_RESUME_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: UPDATE_RESUME_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    }
};

export const deleteResume = (resumeId) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/job/delete-resume/${resumeId}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};